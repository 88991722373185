<div
  id="right-click-target-id-{{ key }}"
  style="margin: 0; padding: 0; display: flex; flex-direction: column; min-height: 100%"
  [ngClass]="{
    's-panel-frame': true,
    'addFitting-mode': false,
    'manage-mode': true
  }"
>
  <div style="display: flex; height: 30px">
    <sysque-select-system
      (valueChanged)="onValueChanged($event)"
      [systemInfo$]="systemInfo$"
      [selectedSystem]="system"
      [panelName]="'manageModePanel'"
    ></sysque-select-system>
    <div class="group-context-menu">
      <div class="group-btn">
        <modus-button
          id="system-button"
          button-style="borderless"
          (click)="systemContextMenu.contextMenuVisibility($event)"
        >
          <img alt="logo" src="assets/img/Group.png" style="transform: rotate(-90deg)" />
        </modus-button>
      </div>
      <sysque-context-menu
        #systemContextMenu
        target="#system-button"
        (contextMenuItemSelected)="handleContextMenuItem($event)"
        (contextMenuOpened)="closeOtherContextMenu(systemContextMenu)"
        [menuItems]="menuItems"
      ></sysque-context-menu>
    </div>
  </div>

  <ng-container style="height: 350px">
    <div style="display: flex; height: 30px">
      <sysque-select-category
        (valueChanged)="onCategoryChanged($event)"
        [categories$]="categoryList$"
        [selectedCategory]="defaultCategory"
      ></sysque-select-category>
      <div class="group-context-menu">
        <div class="group-btn">
          <modus-button
            id="category-button"
            button-style="borderless"
            (click)="categoryContextMenu.contextMenuVisibility($event)"
          >
            <img alt="logo" src="assets/img/Group.png" style="transform: rotate(-90deg)" />
          </modus-button>
        </div>
        <sysque-context-menu
          #categoryContextMenu
          target="#category-button"
          (contextMenuItemSelected)="handleCategoryContextMenuItem($event)"
          (contextMenuOpened)="closeOtherContextMenu(categoryContextMenu)"
          [menuItems]="categoryMenuItems"
        ></sysque-context-menu>
      </div>
    </div>

    <sysque-category-scope
      [key]="key"
      [addFittingMode]="true"
      [manageMode]="true"
      [categoryList$]="categoryList$"
      [fittingList]="fittingList$ | async"
      [defaultCategory]="defaultCategory"
      [selectedSystem]="system"
      (categoryChanged)="onCategoryChanged($event)"
      (copiedItemListChanged)="updateCopiedItemList($event)"
    >
    </sysque-category-scope>

    <dx-context-menu
      class="context-menu"
      *ngIf="this.key === 'dest'"
      [dataSource]="destinationMenuItems"
      target="#right-click-target-id-{{ key }}"
      (onShowing)="onContextMenuShowing($event)"
    >
    </dx-context-menu>
  </ng-container>

  <div
    [ngClass]="{
    'std-position': true,
    'manage-mode-position':  true,
    }"
  >
    <sysque-root-path-selector *ngIf="showRootPath" [manageMode]="false"></sysque-root-path-selector>
  </div>
</div>
