<sysque-frame [componentTitle]="">
  <div class="sysque-about">
    <div class="dialog-base-fill-stroke">
      <div class="content-wrapper">
        <div class="frame-parent">
          <div class="group-wrapper">
            <div class="frame-wrapper">
              <div class="content-wrapper-parent">
                <div class="content-wrapper1">
                  <div class="content-wrapper-inner">
                    <div class="title-wrapper">
                      <label class="title-label">{{ data.productName }}</label>
                      
                    </div>
                  </div>
                </div>
                <div class="side-nav-parts-header">
                  <div class="text">{{ 'Version ' + version }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-wrapper">
            <div class="label-text">{{ data.copyrights }}</div>
          </div>
        </div>
        <div class="frame-group">
          <div class="group-container">
            <img class="frame-child" alt="" src="assets/img/about electrical.png" />
          </div>
          <div class="frame-container">
            <div class="group-frame">
              <div class="group-div">
                <div class="frame-div">
                  <div class="text-parent">
                    <div class="label-text">Company</div>
                    <div class="label-text">{{ data.companyName }}</div>
                  </div>
                  <div class="text-parent">
                    <div class="label-text">User</div>
                    <div class="label-text">{{ userEmail }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="title-parent">
               <label class="title1">Get Help</label>
              <div class="frame-parent1">
                <div class="text-container">
                  <div class="label-text">YouTube Tutorials</div>
                  <modus-tooltip text="https://www.youtube.com/watch?v=XD7NuKFn7C8" position="top">
                    <div class="text7 label-text label-text-link" (click)="openUrl('https://www.youtube.com/watch?v=XD7NuKFn7C8')">
                      SysQue Electrical 101 Training</div>
                  </modus-tooltip>
                  </div>
                  <div class="text-container">
                    <div class="label-text">Community</div>
                    <div class="label-text label-text-link" (click)="openUrl('https://www.trimbleenterprisecommunity.com')">
                      http://www.trimbleenterprisecommunity.com</div>
                  </div>
                <div class="text-container ">
                  <div class="label-text">Support</div>
                  <div class="label-text">mepsupport&#64;trimble.com</div>
                </div>
                <div class="text-container ">
                  <div class="label-text">Phone</div>
                  <div class="label-text">1-800-234-3758</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <span class="bottom-container">
        <dx-button class="done-button" text="Done" (onClick)="closeWindow()"></dx-button>
      </span>
    </div>
    <div class="min-width-320"></div>
  </div>
</sysque-frame>
