<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container" [ngClass]="showWarning ? 'container-with-alert' : 'container-without-alert'">
    <div class="system-container">
      <sysque-select-system
        (valueChanged)="onSystemChanged($event)"
        [systemInfo$]="systems$"
        [selectedSystem]="selectedSystem"
      ></sysque-select-system>
    </div>

    <div class="shape-fitting-container">
      <label class="label-shape">{{ selectedShape }}</label>

      <sysque-select-box
        [data]="fittingTypes$ | async"
        [disabled]="false"
        [showClearButton]="true"
        [placeholder]="fittingTypesPlaceholder"
        [width]="210"
        [height]="30"
        [value]="selectedFittingType"
        (valueChanged)="onFittingTypeChanged($event)"
      ></sysque-select-box>
    </div>

    <div class="fittings-list">
      <div class="list-container">
        <div *ngFor="let column of columnsNames; let i = index">
          {{ column }}
        </div>
      </div>

      <dx-list
        [height]="400"
        [width]="420"
        [selectionMode]="'single'"
        [searchEnabled]="false"
        [dataSource]="fittingPrioritizationRows"
      >
        <dxo-item-dragging
          group="tasks"
          [allowReordering]="true"
          [handle]="'.drag-handle'"
          [onReorder]="reorderLogic"
          [onDragStart]="onDragStart"
        ></dxo-item-dragging>
        <div *dxTemplate="let rowVm of 'item'">
          <div [ngClass]="getRowClass(rowVm)" (click)="toggleSelected(rowVm.order)">
            <fitting-prioritization-row-template [rowVm]="rowVm"> </fitting-prioritization-row-template>
          </div>
        </div>
      </dx-list>
    </div>
  </div>

  <div class="alert-container">
    <sysque-alert
      [showAlertName]="true"
      [alertType]="alertType"
      [message]="warningMessage"
      [isDissmisible]="true"
      (closeButtonClicked)="onDismissClick()"
      *ngIf="showWarning"
    ></sysque-alert>
  </div>

  <div class="bottom-container">
    <dx-button class="button button-revert" text="{{ revertChangesButton }}" (click)="revertChanges()"> </dx-button>
    <dx-button class="button button-save" text="{{ saveOrderButton }}" (click)="saveOrder()"> </dx-button>
  </div>
</sysque-frame>
