<div class="rotate-container">
  <label class="label-title mx-2">Rotate</label>
  <div class="shift-container">
    <dx-button (onClick)="emitRotationCommand(LEFT)">
      <span>
        <img style="width: 24px" src="assets/img/Area=Rotate, State=Rest, Button=Right, Size=Small.png" />
      </span>
    </dx-button>
    <div class="input-padding">
      <dx-number-box
        #numberBox
        class="centered-number"
        [max]="360"
        [min]="0"
        format="#0°"
        [value]="rotationValue"
        [step]="5"
        [showSpinButtons]="true"
        (onValueChanged)="updateRotationValue($event)"
      ></dx-number-box>
    </div>

    <dx-button (onClick)="this.emitRotationCommand(RIGHT)">
      <span>
        <img style="width: 24px" src="assets/img/Area=Rotate, State=Rest, Button=Left, Size=Small.png" />
      </span>
    </dx-button>
  </div>
</div>
