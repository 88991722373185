<div class="main-div position-absolute" #dialog ngDraggable>
  <button class="close-btn" (click)="closeDialog()">✖</button>
  <div class="dialog position-relative">
    <div class="content">
      <h2 class="mb-4">Delete Service Type</h2>
      <div class="d-flex mb-4 align-items-center">
        Are you sure you want to delete this service type?
      </div>
      <div class="actions d-flex">
        <button class="btn btn-text-primary mr-1 flex-1" (click)="discardChanges()">Cancel</button>
        <button class="btn btn-primary ml-1 flex-1" (click)="applyChanges()">Proceed</button>
      </div>
    </div>
  </div>
</div>
