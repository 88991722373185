<div class="expander-list">
    <div *ngFor="let item of items" class="expander-item">
        <div class="header">
            <div class="left-content">
                <title class="expender-title">
                   {{item.title}}
                </title>
            </div>

            <div class="right-content">
                <div class="actions">
                    <div class="toggle-switch">
                        <div class="text-wrapper">
                            <span class="switch-state">{{ item.enabled ? 'On' : 'Off' }}</span>
                        </div>

                        <label class="switch">
                            <input type="checkbox" (click)="onSwitchClick(item)" [(ngModel)]="item.enabled"/>
                            <span class="slider"></span>
                        </label>
                    </div>

                    
                </div>
                <button>
                    <i
                    class="fas"
                    [ngClass]="{ 'fa-chevron-up': item.expanded, 'fa-chevron-down': !item.expanded }"
                    (click)="toggleItem(item)">
                    </i>
                </button>
                
            </div>
        </div>

        <div class="content" *ngIf="item.expanded">
            <ng-container *ngComponentOutlet="
            item.content;
          " />
        </div>
    </div>
</div>