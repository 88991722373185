<div class="cs-item-number-tag-container">
    <sysque-dropdown-options-line 
        [label]="placementLabel" 
        [placeholder]="placementPlaceholder" 
        [selectedValue]="placementSelectedValue"
        [data]="placementItems"
        (valueChanged)="onPlacementValueChanged($event)">
    </sysque-dropdown-options-line>
    <div class="h-line"></div>
    <sysque-check-box-options-line 
        [label]="propertiesLabel" 
        [checkboxItems]="propertiesItems"
        (valueChanged)="onPropertiesValueChanged($event)">    
    </sysque-check-box-options-line>
    <div class="h-line"></div>
    <div class="location1-options-line">
        <sysque-location-options-line
             [label]="locationLabel"
             [placeholder]="locationPlaceholder"
             [locationPath]="locationPath"
             (valueChanged)="onLocationValueChanged($event)"
             (getPath)="getTagLocationPath()">
        </sysque-location-options-line>
    </div>
    <div class="h-line"></div>
    <div class="location2-options-line">
    <sysque-location-options-line
        [label]="fittingLocationLabel"
        [placeholder]="fittingLocationPlaceholder"
        [locationPath]="fittingLocationPath"
        (valueChanged)="onFittingsLocationValueChanged($event)"
        (getPath)="getFittingsTagLocationPath()">
    </sysque-location-options-line>
    </div>
</div>