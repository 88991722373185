import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApplyPanelDataKey, ApplyPanelDataState } from './panel-data-reducer';

export const selectApplyPanelDataState = createFeatureSelector<ApplyPanelDataState>(ApplyPanelDataKey);

export const selectValidElementSelection = createSelector(selectApplyPanelDataState, (state) => state.validSelection);

export const applyPaneldataResult = createSelector(selectApplyPanelDataState, (state) => state.validSelection);
 

