<div class="row-scope drag-handle">
  <div *ngIf="rowVm.selected" class="selected-item-border"></div>
  <div class="column">
    <span class="column-t">{{ rowVm.order }}</span>
    <img *ngIf="rowVm.selected" src="assets/img/move_icon.png" class="drag-icon" alt="logo" />
  </div>
  <div class="desc-sc">
    <span class="desc-t">{{ rowVm.label }}</span>
  </div>
</div>
