import configurationData from '../../assets/configuration/configuration-data.json';
import { Environments } from './environments';

export class Configuration {


  public static getBuildingDataServiceUrl(environment: string): string {
    switch (environment) {
      case Environments.dev:
        return configurationData.buildingDataService.DevelopmentDomain;

      case Environments.stg:
        return configurationData.buildingDataService.StagingDomain;

      case Environments.local:
        return configurationData.buildingDataService.LocalHost;

      case Environments.prod:
        return configurationData?.buildingDataService.ProductionDomain;

      default:
        throw `FATAL: Unsupported service environment ${environment} for BuildingDataService detected !!!`;
    }
  }

}
