<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container">
    <div class="tab-container">
      <sysque-annotation-settings-tab #child (validationResult)="handleValidationResult($event)">
      </sysque-annotation-settings-tab>
    </div>
    <div class="bottom-container">
      <div class="alert-container">
        <sysque-alert
          [alertType]="alertType"
          [message]="warningMessage"
          [isDissmisible]="true"
          [showAlertName]="true"
          (closeButtonClicked)="onDismissClick()"
          *ngIf="warningMessage"
        >
        </sysque-alert>
      </div>
      <dx-button
        id="discard-button"
        class="discard-button"
        text="{{ data.discardButtonName }}"
        (onClick)="discard()"
        [disabled]="isDiscardButtonDisabled"
      >
      </dx-button>
      <dx-button
        id="apply-button"
        class="apply-button"
        text="{{ buttonName }}"
        (onClick)="apply()"
        [disabled]="isApplyButtonDisabled"
      >
      </dx-button>
    </div>
  </div>
</sysque-frame>
