<div class="ct-spool-tag-container">
    <sysque-dropdown-options-line 
        [label]="placementLabel" 
        [placeholder]="placementPlaceholder" 
        [selectedValue]="placementSelectedValue"
        [data]="placementItems"
        (valueChanged)="onPlacementValueChanged($event)">
    </sysque-dropdown-options-line>
    <div class="offset-container">
        <sysque-number-box-options-line 
            [numberBoxItems]="offsetItems"
            [showSpinButtons]="showOffsetSpinButtons"
            (valueChanged)="onOffsetValueChanged($event)">
        </sysque-number-box-options-line>
    </div>
    <div class="h-line"></div>
    <div class="occurrence-container">
        <sysque-check-box-options-line 
            [label]="occurrenceLabel" 
            [checkboxItems]="occurrenceItems"
            (valueChanged)="onOccurrenceValueChanged($event)">
        </sysque-check-box-options-line>
    </div>
    <div class="h-line"></div>
    <sysque-check-box-options-line 
        [label]="propertiesLabel" 
        [value]="propertiesSelectedValue"
        [checkboxItems]="propertiesItems"
        (valueChanged)="onPropertiesValueChanged($event)">    
    </sysque-check-box-options-line>
    <div class="h-line"></div>
    <sysque-number-box-options-line 
        [label]="ignoreStraightsLabel" 
        [numberBoxItems]="ignoreStraightsItems"
        [showSpinButtons]="ignoreStraightsSpinButtons"
        (valueChanged)="onIgnoreStraightValueChanged($event)">
    </sysque-number-box-options-line>
    <div class="h-line"></div>
    <div class="location1-options-line">
        <sysque-location-options-line
             [label]="locationLabel"
             [placeholder]="locationPlaceholder"
             [locationPath]="locationPath"
             (valueChanged)="onLocationValueChanged($event)"
             (getPath)="getTagLocationPath()">
        </sysque-location-options-line>
    </div>
    <div class="h-line"></div>
    <div class="location2-options-line">
    <sysque-location-options-line
        [label]="fittingLocationLabel"
        [placeholder]="fittingLocationPlaceholder"
        [locationPath]="fittingLocationPath"
        (valueChanged)="onFittingsLocationValueChanged($event)"
        (getPath)="getFittingsTagLocationPath()">
    </sysque-location-options-line>
    </div>
</div>