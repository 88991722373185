<div class="segment-container">
  <label class="label-title mx-2">Shift Segment</label>
  <div class="shift-container">
    <dx-button (onClick)="emitShiftCommand(shiftElementDirections.MoveLeftEnd)">
      <span>
        <img style="width: 24px;" src="assets/img/Area=Shift, State=Rest, Button=Leftist, Size=Small.png" />
      </span>
    </dx-button>

    <dx-button (onClick)="emitShiftCommand(shiftElementDirections.MoveLeft)">
      <span>
        <img style="width: 24px" src="assets/img/Area=Shift, State=Rest, Button=Left, Size=Small.png" />
      </span>
    </dx-button>

    <dx-button (onClick)="emitShiftCommand(shiftElementDirections.MoveRight)">
      <span>
        <img style="width: 24px" src="assets/img/Area=Shift, State=Rest, Button=Right, Size=Small.png" />
      </span>
    </dx-button>

    <dx-button (onClick)="emitShiftCommand(shiftElementDirections.MoveRightEnd)">
      <span>
        <img style="width: 24px" src="assets/img/Area=Shift, State=Rest, Button=RIghtest, Size=Small.png" />
      </span>
    </dx-button>
  </div>
</div>
