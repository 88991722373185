<dx-tree-view
  id="fitting-treeview"
  [dataSource]="dataSource"
  [selectionMode]="'single'"
  [width]="'100%'"
  [height]="'100%'"
  [ngClass]="'fitTreeView'"
  (onItemExpanded)="onItemExpanded($event)"
  (onItemClick)="onItemClick($event)"
  (onItemCollapsed)="onItemCollapsed($event)"
>
</dx-tree-view>
