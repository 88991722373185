export interface DocumentResult {
  base64Image: string;
  catalogLocation: string;
  catalogName: string;
  componentGroup: string;
  componentGroupID: number;
  discontinued: boolean;
  fittingEntryID: number;
  fittingIDNumber: string;
  fittingTypeID: number;
  fittingUnitsName: string;
  installType: string;
  installTypeID: number;
  isFittingDataDocument:boolean ;
  longDescription: string;
  manufProductLineID: number;
  manufacturerAbbreviation: string;
  manufacturerID: number;
  manufacturerName: string;
  materialID: number;
  materialName: string;
  materialSchedule: string;
  numberOfConnections: number;
  product: string;
  productDiscipline: string;
  productDisciplineID: number;
  productLine: string;
  productURL: string;
  schedule: string;
  scheduleID: number;
  shortDescription: string;
  subProductID: number;
  subProductName: string;
  sysQueLongDescription: string;
  textMatchScore: string;
  unitsName: string;
  unitsOriginID: number;
  valveClass: string;
  valveClassID: number;
  _id: string;
  sizes:any;
}
