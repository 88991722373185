export class FittingTreeView {
    id!: string;
    text!: string;
    expanded!: boolean;
    isFolder!: boolean;
    image!: string;
    scheduleID!: string;
    fittingEntryID!: number;
    fittingIDNumber!: string;
    manufacturerName!: string;
    items!: FittingTreeView[];
  
  }