import { Component, EventEmitter, Output } from '@angular/core';
import { setManageModeAction } from '../../+state/toolbelt.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sysque-manage-btn',
  templateUrl: './manage-btn.component.html',
  styleUrls: ['./manage-btn.component.scss'],
})
export class ManageBtnComponent {
  rootPathValue="";
  @Output()notify: EventEmitter<any> = new EventEmitter();
  constructor(private store:Store) {}
  
  setManageMode(){
    this.notify.emit();
    this.store.dispatch(setManageModeAction({mode: true}))
    
  }
}
