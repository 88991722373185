<div class="row-scope" [ngClass]= "{'drag-handle': rowVm.selected && allSystemsSelected}">
  <div *ngIf="rowVm.selected" class="selected-item-border"></div>
  <div class="column-1">
    <span class="column-1-t">{{ rowVm.order }}</span>
    <img
      *ngIf="!exportClicked && allSystemsSelected && rowVm.selected"
      src="assets/img/move_icon.png"
      class="drag-icon"
      alt="logo"
    />
    <modus-checkbox
      *ngIf="exportClicked"
      [checked]="isChecked"
      (checkboxClick)="onCheckedChange($event)"
      class="export-checkbox"
      checked
    ></modus-checkbox>
  </div>
  <modus-switch
    (click)="onSwitchChange()"
    checked="{{ rowVm.switchValue }}"
    label=""
    size="small"
    class="switch-sc"
  ></modus-switch>
  <div class="desc-sc">
    <span class="desc-t">{{ rowVm.label }}</span>
  </div>
  <div class="group-context-menu">
    <div class="group-btn">
      <modus-button button-style="borderless" (click)="contextMenu.contextMenuVisibility($event)">
        <img alt="logo" src="assets/img/Group.png" style="transform: rotate(-90deg)" />
      </modus-button>
    </div>
    <sysque-context-menu
      #contextMenu
      target="#modus-button"
      [ngClass]="{ 'upward-menu': needUpwardMenu }"
      (contextMenuOpened)="handleContextMenuVisibility()"
      (contextMenuItemSelected)="handleContextMenuItem($event, rowVm.label)"
      [menuItems]="menuItems"
    ></sysque-context-menu>
  </div>
</div>
