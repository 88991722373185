<dx-tooltip
  target="{{ tooltipData.target }}"
  position="bottom"
  [visible]="tooltipData.isHidden"
  [hideOnOutsideClick]="false"
>
  <div class="tooltipLayout">
    <div class="frameLayout">
      <div class="subtitle">{{ tooltipData.fittingName }}</div>
      <div class="content-container">
        <div class="contentLayout">
          <div class="itemLayout" *ngFor="let item of tooltipData.tooltipItems">
            <div class="fitItemheader">{{ item.header }}</div>
            <div class="fitItem">
              <div *ngIf="getHtmlContent(item).templateName === 'default'">
                {{ getHtmlContent(item).data[0] }}
              </div>
              <div *ngIf="getHtmlContent(item).templateName === 'size'">
                <div class="size-content">
                  <ng-container *ngFor="let d of item.valueAsArray; let isLast = last; let isFirst = first">
                    <span
                      class="size-line"
                      [ngClass]="isFirst ? 'firstLine' : endsWith(d) ? 'darkGraySpace' : 'preSpace'"
                    >
                      {{ slice(d, isLast) }}
                    </span>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="getHtmlContent(item).templateName === 'color'">
                <div class="service-color-content" [style.background-color]="'rgb(' + item.value + ')'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-tooltip>
