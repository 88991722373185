<div [ngClass]="visibility?'visible':'hide'"
  style="z-index: 2; position: absolute; margin-top: 280px; margin-left: 36px; width: 300px;">
  <sysque-progress-bar #sysqueProgress [actionName]="'Searching'" [actionText]="'Loading Components'"
    [value]="value"></sysque-progress-bar>
</div>
<div [ngClass]="visibility?'hide':'visible'"
  style="display: flex; flex-direction: column; z-index: 1; position: relative">
  <div class="result-container">
    <div *ngFor="let item of this.resultInformation">
      <div style="display: flex; flex-direction: column;  align-items: center;">
        <img id="f_{{ item.FittingId }}" src="{{item.ImagePath}}"
          style="transform: rotate(0deg); width: 58px; height: 58px" alt="logo" />
        <label>{{ item.NameResult?.length > 6? item.NameResult?.substring(0,5): item.NameResult }}...</label>
        <dx-context-menu [dataSource]="item.sizes" target="#f_{{ item.FittingId }}" (onItemClick)="itemClick(item)">
          <div *dxTemplate="let e of 'item'">
            <div class="item-container">
              <div>
              </div>
              <div>
                {{ e.text }}</div>
              <span *ngIf="e.items" class="dx-icon-spinright menu-icon-font-size" style="margin-left: auto;"></span>
            </div>
          </div>
        </dx-context-menu>
      </div>
    </div>
  </div>
  <button id="showMoreBnt" *ngIf="resultInformation.length > 0  && showMoreBtn()"
    [ngClass]="resultInformation.length === 0 ||  resultInformation.length % 100 > 0 ? 'show-more-button' : 'show-more-button-enable'"
    [disabled]="resultInformation.length === 0 || resultInformation.length % 100 > 0" (click)="getNext()">
    <div
      [ngClass]="resultInformation.length ===  0 ||  resultInformation.length % 100 > 0 ? 'show-more-label' : 'show-more-label-enable'">
      Show More..
    </div>
  </button>
</div>