<div class="pb-container">
  <div class="pb-c-s1">
    <div class="pb-c-s2">
      <div class="pb-c-s3">
        <div class="pb-c-s4">
          <div class="pb-c-s6">
            <img class="ticons" alt="info" src="assets/img/Information.png" />
            <div class="pb-c-s7">
            </div>
          </div>
        </div>
        <div class="pb-c-s5">
          <div class="action-name">
            {{ actionName }}
          </div>
          <div class="action-text">
            {{ actionText }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bar-p">
    <dx-progress-bar [min]="0" [max]="99" [value]="value" [statusFormat]="format">
    </dx-progress-bar>
  </div>
</div>