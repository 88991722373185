import { InjectionToken } from '@angular/core';
import { ResultInformation, SysqueElectricalState } from '@hcworkspace/sysque/toolbelt/feature';
import { CurateSearchVm } from 'libs/sysque/toolbelt/feature/src/lib/models/curate-search-vm';
import { ResultItemInformation } from 'libs/sysque/toolbelt/feature/src/lib/models/result-item-information';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IResultContainerFacade {
  result$: Observable<ResultInformation[]>;
  notifySelectorChange$: BehaviorSubject<string>;
  selectTreeVewResult(): Observable<ResultInformation[]>;
  getNext(lastCurateSearchVm: CurateSearchVm, count: number): any;
  selectLastCurateSearchQuery(): Observable<CurateSearchVm>;
  selectCurateSearchResult(): Observable<ResultInformation[]>;
  getLocalStorageUpdate(): Observable<string>;
  getLocalStorageItem(key: string): any;
  getAddFittingState(): SysqueElectricalState;
  updateLocalStorage(key: string, value: any): void;
  addToSystem(
    itemInfo: ResultItemInformation,
    selectedSystem: string,
    selectedCategory: string,
    isCustomContent: string
  ): void;
}

export const RESULT_CONTAINER_FACADE_TOKEN = new InjectionToken<IResultContainerFacade>(
  'RESULT_CONTAINER_FACADE_TOKEN '
);
