import { createReducer, on } from "@ngrx/store";
import { getCurateSearchOptionsActionSuccess, searchForCurateSearchActionSuccess, showAddFittingResultPanelAction } from "./curate-search-actions";
import { ResultInformation } from "../../models/result-information";

export const CurateSearchKey = "CurateSearch";

export interface CurateSearchState {
  curateSearchResult?: ResultInformation[],
  lastCurateSearchQuery?: any,
  curateSearchLastIndex?: number,
  showAddFittingResultPanel?: boolean,
  curateSearchOptions?: any,
}

export const initialCurateSearchState: CurateSearchState = {
  curateSearchLastIndex: 0,
  curateSearchResult: [],
  curateSearchOptions: {
    groups: [],
    installTypes: [],
    manufacturers: [],
    productTypes: [],
    subProductTypes: [],
  },
}

export const CurateSearchReducer = createReducer(
  initialCurateSearchState,

  on(showAddFittingResultPanelAction, (state, action) => ({
    ...state,
    showAddFittingResultPanel: action.mode,
  })),

  on(searchForCurateSearchActionSuccess, (state, action) => ({
    ...state,
    curateSearchResult: addCurateSearchResult(state, action.response, action.resetIndex),
    lastCurateSearchQuery: action.lastCurateSearchQuery,

  })),

  on(getCurateSearchOptionsActionSuccess, (state, action) => ({
    ...state,
    curateSearchOptions: action.response,

  })));

export function addCurateSearchResult(state: CurateSearchState, response: ResultInformation[], resetIndex: boolean): ResultInformation[] {
  var oldResult = state.curateSearchResult;
  if (resetIndex) {
    oldResult = [];
  }
  oldResult?.forEach(element => { response.push(element) });
  return response;
}