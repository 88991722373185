<div class="p-system">
  <div class="p-container">
    <div class="panel-name" #panelContainer
   >
      <dx-select-box #panelBoxContainer style="width: 100%;" [dataSource]="panelsList" displayExpr="panelName" placeholder="Panel Name"
        (onValueChanged)="onPanelValueChanged($event)" itemTemplate="item">
        <div *dxTemplate="let data of 'item'">
          <span>
            <span>{{ data.projectName }} : {{ data.panelName }} </span>
          </span>
        </div>
        <div *dxTemplate="let data of 'dropDownButton'">
          <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
        </div>
      </dx-select-box>
    </div>   
  </div>

  <sysque-check-box-container *ngIf="!addFittingMode" (circuitListUpdated)="getUpdatedCircuit($event)"
    [data]="checkBoxConfigs">
  </sysque-check-box-container>
</div>