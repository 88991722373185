<div class="top-container">
    <label class="title">{{dataGrid.title}}</label>
    <sysque-select-box 
        [width]="114" 
        [height]="30" 
        [value]="selectedMinSizeRange"
        [data]="sizeMinRanges"
        (valueChanged)="onMinSizeRangeChanged($event.value)"
        placeholder="Min">
    </sysque-select-box>
    <sysque-select-box 
        [width]="114" 
        [height]="30" 
        [value]="selectedMaxSizeRange"
        [data]="sizeMaxRanges"
        (valueChanged)="onMaxSizeRangeChanged($event.value)"
        placeholder="Max">
    </sysque-select-box>
</div>
<div class="circuit-grid-body">
    <dx-data-grid id="gridContainer" 
        #sizeGrid 
        keyExpr="id"
        [dataSource]="dataGrid.sizeRangeItems" 
        [columns]="columns"
        [columnAutoWidth]="dataGrid.columnAutoWidth"
        [columnHidingEnabled]="dataGrid.columnHidingEnabled" 
        [allowColumnResizing]="dataGrid.allowColumnResizing" 
        [columnResizingMode]="dataGrid.columnResizingMode" 
        [columnMinWidth]="dataGrid.columnMinWidth"
        [showColumnLines]="dataGrid.showColumnLines"
        [showRowLines]="dataGrid.showRowLines"
        [selection]="dataGrid.selection" 
        [selectedRowKeys]="selectedRowKeys"
        [height]="dataGrid.height"
        (onSelectionChanged)="onSelectionChanged($event)"
        [editing]="{
          mode: 'cell',
          allowUpdating: true
        }"
        (onCellPrepared)="onCellPrepared($event)"
        >
        <dxo-column-resizing [enabled]="true"></dxo-column-resizing>
        <dxo-sorting [mode]="'none'"></dxo-sorting>
    </dx-data-grid>
</div>