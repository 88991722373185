<sysque-frame [componentTitle]="componentTitle">
  <div
    class="content-container"
    [ngStyle]="{ width: areDuplicates ? '100%' : '350px', height: areDuplicates ? '490px' : '400px' }"
  >
    <div class="systems">
      <div class="system-container" *ngFor="let systemName of keys(systemsDictionary)">
        <div class="system-name" [ngStyle]="{ width: areDuplicates ? '60%' : '100%' }">
          {{ systemName }}
        </div>
        <div [ngStyle]="{ visibility: !systemsErrors[systemName] ? 'hidden' : 'visible' }">
          <span id="e_{{ removeSpaces(systemName) }}" class="error-icon"> &#9888; </span>
          <dx-tooltip
            target="#e_{{ removeSpaces(systemName) }}"
            position="top"
            showEvent="mouseenter"
            hideEvent="mouseleave"
            [hideOnOutsideClick]="false"
          >
            <div>{{ systemsErrors[systemName] }}</div>
          </dx-tooltip>
        </div>
        <div class="input-container" *ngIf="isDuplicate(systemName)">
          <input
            class="input-text"
            [ngClass]="!systemsErrors[systemName] ? 'input-text-no-error' : 'input-text-error'"
            type="text"
            [(ngModel)]="systemsDictionary[systemName]"
            placeholder="System Name"
            (input)="onUserInputChanged(systemName)"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="alert-container">
    <sysque-alert
      [alertType]="alertType"
      [message]="alertMessage"
      [isDissmisible]="false"
      [alertName]="alertName"
      [showAlertName]="true"
      *ngIf="showAlert"
      (closeButtonClicked)="onDismissClick()"
    ></sysque-alert>
  </div>
  <div
    class="bottom-container"
    [ngStyle]="{ width: areDuplicates ? '100%' : '350px', padding: areDuplicates ? '24px' : '24px 12px 24px 12px' }"
    [ngClass]="{
      'bottom-container-duplicate': areDuplicates,
      'bottom-container-no-duplicate': !areDuplicates
    }"
  >
    <dx-button
      class="button-cancel"
      [ngStyle]="{ width: areDuplicates ? '218px' : '150px' }"
      text="{{ cancelButtonName }}"
      (click)="closeWindow()"
    >
    </dx-button>
    <dx-button
      class="button-import"
      [ngStyle]="{ width: areDuplicates ? '218px' : '150px' }"
      [ngClass]="{
        'button-import-duplicate': areDuplicates,
        'button-import-no-duplicate': !areDuplicates
      }"
      text="{{ importButtonName }}"
      [disabled]="!inputCompleted"
      (click)="import()"
    >
    </dx-button>
  </div>
</sysque-frame>
