<div class="dropdown-container">
        <label class="dropdown-label">{{label}}</label>
        <sysque-select-box class="dropdown-select-box"
            [data]="data" 
            [disabled]="disabled" 
            [showClearButton]="showClearButton"
            [placeholder]="placeholder" 
            [width]="width" 
            [height]="height" 
            (valueChanged)="onValueChanged($event.value)"
            [value]="selectedValue">
        </sysque-select-box>
</div>