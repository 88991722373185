<div class="main-div position-absolute" #dialog ngDraggable>
  <button class="close-btn" (click)="closeDialog()">✖</button>
  <div class="dialog position-relative">
    <div class="content">
      <h2 class="mb-4">Create New Service Type</h2>
      <div class="d-flex mb-4 align-items-center">
        <input class="flex-1 service-type border" type="text" id="service-type" [(ngModel)]="currentServiceType" />
      </div>
      <div class="sysque-alert">
        <sysque-alert 
                [showAlertName]="true" 
                [alertType]="alertType" 
                [message]="warningMessage" 
                [isDissmisible]="true"
                *ngIf="warningMessage"
                (closeButtonClicked)="onAlertCloseButtonClick()">
        </sysque-alert>
      </div>
      <div class="actions d-flex">
        <button class="btn btn-text-primary mr-1 flex-1" (click)="discardChanges()">Discard</button>
        <button class="btn btn-primary ml-1 flex-1" (click)="applyChanges()">Add</button>
      </div>
    </div>
  </div>
</div>

