import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { getCurateSearchOptionsAction, getCurateSearchOptionsActionSuccess, searchForCurateSearchAction, searchForCurateSearchActionSuccess } from './curate-search-actions';
import { CurateSearchService } from '../../../../../../../../libs/sysque/toolbelt/utils/src/lib/sysque-backend/curate-search.service';
import { from } from 'rxjs';


@Injectable()
export class CurateSearchEffects {
  constructor(private readonly actions$: Actions, private curateSearchService: CurateSearchService) { }

  searchForCurateSearchAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(searchForCurateSearchAction),
      concatMap((action) =>
        from(this.curateSearchService.getFittingDataByQuery(action.query, action.resetIndex, action.count)).pipe(
          map(
            (data) =>
              data &&
              searchForCurateSearchActionSuccess({
                response: data,
                lastCurateSearchQuery: action.query,
                resetIndex: action.resetIndex,
              })
          )
        )
      )
    );
  });

  getCurateSearchOptionsAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCurateSearchOptionsAction),
      concatMap(() =>
        this.curateSearchService
          .getCurateSearchOption()
          .pipe(map((data) => data && getCurateSearchOptionsActionSuccess({ response: data })))
      )
    );
  });
}
