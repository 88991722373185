import { SelectionVm } from './selection-vm';

export class CurateSearchVm {
  manufacturerID!: number;
  materialID!: number;
  componentGroupID!: number;
  installTypeID!: number;
  productDisciplineID!: number[];
  searchWords!: string;
  startRecordIndex!: number;
  recordCount!: number;
  discontinued = false;
}

export class QueryParams {
  SearchWords!: string; // = SearchTextOnline.Text,
  StartRecordIndex!: number; // = startIndexSearch,
  RecordCount!: number; // = RecordCount,
  ManufacturerID!: number; // = manufacturerId,
  MaterialID!: number; // = materialId,
  ComponentGroupID!: number; // = componentGroupId,
  InstallTypeID!: number; // = installTypeId,
  SubProductID!: number; // = subProductTypeId,
  FittingTypeID!: number; // = productTypeId,
  UnitsOriginID!: number; // = unitTypeId
  Discontinued=false;
}
