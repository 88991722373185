<div class="main-container">
    <div class="circuit-container">
        <div class="circuit-name mt-1">Circuit Names</div>
    </div>
    <div class="items-container">
        <div class="box-container">
            <div *ngFor="let item of  data" >
                <modus-checkbox
                    checked="{{item.checked}}"   
                    (checkboxClick)="onCheckBoxClicked(item)"   
                    label="{{item.label}}" >
                </modus-checkbox>
            </div>
        </div>
      
    </div>
</div>