import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CurateSearchState } from "./curate-search-reducer";
import * as fromCurateSearchReducer from './curate-search-reducer';

export const selectCurateSearchState = createFeatureSelector<CurateSearchState>(fromCurateSearchReducer.CurateSearchKey);

export const selectCreateSearchOptions= createSelector(selectCurateSearchState, (state) => state?.curateSearchOptions);

export const selectCurateSearchResult= createSelector(selectCurateSearchState, (state) => state?.curateSearchResult);

export const selectShowAddFittingResultPanel = createSelector(selectCurateSearchState, (state) => state?.showAddFittingResultPanel);

export const selectLastCurateSearchQuery= createSelector(selectCurateSearchState, (state) => state.lastCurateSearchQuery);
