<div class="all-container">


  <div class="s-container" [ngStyle]="{ 'width.px': '100%', 'height.px': '100%' }">
    <div class="s-pannel-tools">
      <sysque-electrical-toolbelt class="h-100"
        (emitProcessEnable)="emitProcessEnable($event)"
        (emitShiftElementReturnCode)="emitShiftElementReturnCode($event)"
        (emitRotateElementReturnCode)="emitRotateElementReturnCode($event)"
        [toolItems]="toolBeltItems$ | async"
      ></sysque-electrical-toolbelt>
    </div>

    <div class="v-line"></div>

    <div class="s-content c-pos-2">
      <sysque-system-panel-frame
        [manageMode]="false"
        [showRootPath]="true"
        (pasteError)="onPasteError($event)"
        (showDuplicateError)="onShowDuplicateError($event)"
      ></sysque-system-panel-frame>
    </div>

    <div *ngIf="isLoading" class="loading-overlay">
      <div style="z-index: 2; width: 70%">
        <sysque-progress-bar
          #sysqueProgress
          [actionName]="progressBarTitle"
          [actionText]="progressBarBody"
          [value]="false"
        ></sysque-progress-bar>
      </div>
    </div>

    <div *ngIf="!processEnabled" class="processing-surface">
      <div>
        <sysque-alert
          class="process-alert-container"
          [alertType]="alertType"
          [message]="alertMessage"
          [showAlertName]="true"
          [alertName]="alertName"
          [isDissmisible]="showClose"
        >
        </sysque-alert>
      </div>
    </div>

    <div *ngIf="areMissing || isError" class="loading-overlay">
      <dx-dialog id="errorDownload">
        <div class="dialog-content">
          <p class="dialog-title">{{ dialogTitle }}</p>
          <p class="dialog-body">{{ dialogBody }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="onConfirmationClick()">{{ confirmationButton }}</button>
          <button class="button-yes-no" [hidden]="isDisapprovalButtonDisabled" (click)="onDisapprovalClick()">
            {{ disapprovalButton }}
          </button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf = "isEditDuplicateElectricalItemAlert" class="loading-overlay">
      <dx-dialog id="errorDownload">
        <div class="dialog-content">
          <p class="dialog-title">{{ dialogTitle }}</p>
          <p class="dialog-body">{{ dialogBody }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="onOkClick()">Yes</button>
          <button class="button-yes-no" (click)="onNoClick()">No</button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf="isAlertShown" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">Alert</p>
          <p class="dialog-body">{{ alertMessage }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="okClick()">OK</button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf="showDialog" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">{{ dialogTitle }}</p>
          <p class="dialog-body">{{ dialogBody }}</p>
        </div>

        <span>
          <modus-checkbox
            class="replaceCheckBox"
            (checkboxClick)="onReplaceAll($event)"
            [checked]="replaceAll"
            [label]="'Do this for additional occurrences'"
          ></modus-checkbox>
        </span>
        <div class="button-grid">
          <button class="button-cancel-proceed" (click)="onCancelClick()">Cancel</button>
          <button class="button-cancel-proceed" (click)="onReplaceClick()">Proceed</button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf="showElementMovement" class="loading-overlay">
      <dx-dialog id="errorDownload">
        <div class="dialog-content">
          <p class="dialog-title">Selection Checking</p>
          <p class="dialog-body">{{ elementMovementDialog }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="onShiftValueOk()">OK</button>
        </div>
      </dx-dialog>
    </div>
    <sysque-acrylic-surface></sysque-acrylic-surface>
    <div *ngIf="isInvalidShortcut" class="loading-overlay">
      <dx-dialog id="errorDownload">
        <div class="dialog-content">
          <p class="dialog-title">{{ dialogTitle }}</p>
          <p class="dialog-body">{{ dialogBody }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="openElectricalSettings()">OK</button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf="isMsgPopupVisible" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">{{ dialogTitle }}</p>
          <p class="dialog-body">{{ dialogBody }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="okClick()">OK</button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf="isWorksharingSyncInProgress || isProgressBarVisible" class="loading-overlay">
      <div style="z-index: 2; width: 70%">
        <sysque-progress-bar
          #sysqueProgress
          [actionName]="progressBarTitle"
          [actionText]="progressBarBody"
          [value]="false"
        ></sysque-progress-bar>
      </div>
    </div>
    <div *ngIf="shouldDisplayPopup() || isTetherConfiguration" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">{{ popupDialogTitle }}</p>
          <p class="dialog-body">{{ popupDialogBody }}</p>
        </div>
        <div class="button-grid">
          <button class="button-yes-no" (click)="onOkClickTetherConfiguration()">OK</button>
        </div>
      </dx-dialog>
    </div>
  </div>
  <div adPopupDirective></div>
</div>
