<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container">
    <div class="id-container">
      <input
        type="text"
        [(ngModel)]="id"
        placeholder="{{ data.textId }}"
        class="id-input"
        (input)="onUserInputChanged()"
      />
    </div>
    <div class="alert-container">
      <sysque-alert
        [alertType]="alertType"
        [message]="warningMessage"
        [isDissmisible]="true"
        [showAlertName]="true"
        *ngIf="warningMessage"
        (closeButtonClicked)="onAlertCloseButtonClick()"
      ></sysque-alert>
    </div>

  <div class="bottom-container">
    <dx-button
      id="apply-button"
      class="apply-button"
      [ngClass]="{ 'disabled-apply-button': isApplyButtonDisabled }"
      text="{{ data.buttonName }}"
      (click)="applyId()"
      [disabled]="isApplyButtonDisabled"
    >
    </dx-button>
  </div>
</div>
</sysque-frame>
