import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PanelDataService } from '../../../../../utils/src/lib/sysque-backend/panel-data.service';
import { concatAll, concatMap, map, mergeMap } from 'rxjs/operators';

import {
  ChangeApplyPanelDialogSize,
  ChangeApplyPanelDialogSizeSuccess,
  ValidatePanelCircuitData,
  ValidatePanelCircuitDataSuccess,
  ApplyPanelDataSuccess} from './panel-data-actions';

@Injectable()
export class ApplyPanelDataEffects {
 
  constructor(private readonly actions$: Actions, private panelService: PanelDataService) { }  

   validatePanelData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ValidatePanelCircuitData),
      mergeMap(() =>
        this.panelService.validatePanelCircuitData().pipe(map((data) => ValidatePanelCircuitDataSuccess({response:data})))
      )
    );
   });

   changeDialogDimensions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChangeApplyPanelDialogSize),
      concatMap((action) =>
        this.panelService.changeDialogDimensions(action.height).pipe(map((data) => data && ChangeApplyPanelDialogSizeSuccess({ response: data })))
      )
    );
  });
}
