<div [ngClass]="getClassType()">
  <div class="alert-content">
    <div class="alert-title">
      <img *ngIf="alertType" class="ticons" alt="{{ altText }}" src="{{ imagePath }}" />
      <div class="alert-name" *ngIf="showAlertName">{{ alertName }}</div>
    </div>
    <div class="sysque-alert-text" [innerHTML]="message"></div>
  </div>
  <img *ngIf="isDissmisible" class="ticonsX" alt="close" src="assets/img/close.png" (click)="onDismissClick()" />
</div>
