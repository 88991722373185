import { createReducer, on } from '@ngrx/store';
import {
  InitializeHangerSpecificationsSuccess,
  InitializeRootPathSuccess,
  InitializeSystemInfoSuccess,
  InitializeToolbeltAccordionGroupsSuccess,
  ShowSysquePopupAction,
  UpdateSystemInfoSuccess,
  UpdateRootPathSuccess,
  getCategoryListActionSuccess,
  getFittingsListActionSuccess,
  setAddFittingModeAction,
  setManageModeAction,
  initializeProjectListSuccess,
  getProcessFittingStatusSuccess,
  InitializePanelListSuccess,
  updateIsClearanceSuccess,
  updateIsHangerNeededSuccess,
  CheckInvalidShortcutsSuccess,
  GetConfigurationLocationSuccess,
  GetProjectsListSuccess,
} from './toolbelt.actions';
import { SystemInfo } from 'libs/sysque/toolbelt/data-access/src/lib/domain/system-info';
import { TabDescription } from 'libs/sysque/toolbelt/data-access/src/lib/domain/tab-description';
import { HangerSpecification } from 'libs/sysque/toolbelt/data-access/src';
import { SysquePopupConfig, ToolGroup } from '@hcworkspace/sysque/toolbelt/feature';
import { CurateSearchVm } from '../models/curate-search-vm';
import { Item } from 'libs/sysque/toolbelt/data-access/src/lib/domain/item';
import { FittingTreeView } from '../models/fitting-tree-view-vm';
import { PanelDetails } from '../models/panel-detail';
import { BooleanObject } from '../models/boolean-object';
import { GlobalConstants } from '../constants/global-constants';
import { ElectricalSettingsReturnCodes } from '../models/electrical-settings-return-codes';
import { ProjectRecords } from '../models/project-records';
export const toolBeltFeatureKey = 'ToolBelt';

export interface ToolBeltState {
  accordionGroups: ToolGroup[];
  systemInfo: SystemInfo[];
  categoryList: TabDescription[];
  fittingList: Item[];
  hangerSpecifications: HangerSpecification[];
  sysquePopupConfig?: SysquePopupConfig;
  addFittingMode: boolean;
  manageMode: boolean;
  curateSearchLastIndex: number;
  hideGetMoreCurateSearchResult: boolean;
  lastCurateSearchQuery?: CurateSearchVm;
  updateRootPath: string;
  rootPath: string;
  treeViewItems: any;
  updatedTreeViewItem: FittingTreeView;
  projectList: string[];
  wireFillList: string[];
  varList: boolean[];
  processStatus: BooleanObject;
  isCableTaryType: BooleanObject;
  dockablePanelList: PanelDetails[];
  circuitList: string[];
  isHangerUpdated: BooleanObject;
  isClearanceUpdated: BooleanObject;
  isProcessFittingsOnly: string;
  invalidShortcut: ElectricalSettingsReturnCodes;
  configurationLocation: string;
  projects: ProjectRecords[];
}

export const initialState: ToolBeltState = {
  accordionGroups: [],
  systemInfo: [],
  categoryList: [],
  fittingList: [],
  hangerSpecifications: [],
  sysquePopupConfig: undefined,
  addFittingMode: false,
  manageMode: false,
  curateSearchLastIndex: 0,
  hideGetMoreCurateSearchResult: true,
  rootPath: '',
  updateRootPath: '',
  treeViewItems: [],
  updatedTreeViewItem: new FittingTreeView(),
  projectList: [],
  wireFillList: [],
  varList: [],
  processStatus: { value: false } as BooleanObject,
  isCableTaryType: { value: false } as BooleanObject,
  dockablePanelList: [],
  circuitList: [],
  isHangerUpdated: { value: false } as BooleanObject,
  isClearanceUpdated: { value: false } as BooleanObject,
  isProcessFittingsOnly: '',
  invalidShortcut: ElectricalSettingsReturnCodes.Success,
  configurationLocation: '',
  projects: [],
};

export const toolbeltReducer = createReducer(
  initialState,

  on(InitializeToolbeltAccordionGroupsSuccess, (state, action) => ({
    ...state,
    accordionGroups: action.accordionGroups,
  })),

  on(InitializeSystemInfoSuccess, (state, action) => {
    localStorage.setItem(GlobalConstants.SYSTEM_INFO, JSON.stringify(action.systemInfo));
    return {
      ...state,
      systemInfo: action.systemInfo,
    };
  }),

  on(UpdateSystemInfoSuccess, (state, action) => {
    localStorage.setItem(GlobalConstants.SYSTEM_INFO, JSON.stringify(action.systemInfo));
    return {
      ...state,
      systemInfo: action.systemInfo,
    };
  }),

  on(InitializeHangerSpecificationsSuccess, (state, action) => ({
    ...state,
    hangerSpecifications: action.hangerSpecifications,
  })),

  on(ShowSysquePopupAction, (state, action) => ({
    ...state,
    sysquePopupConfig: action.sysquePopupConfig,
  })),

  on(setAddFittingModeAction, (state, action) => ({
    ...state,
    addFittingMode: action.mode,
  })),

  on(setManageModeAction, (state, action) => ({
    ...state,
    manageMode: action.mode,
  })),

  on(getCategoryListActionSuccess, (state, action) => ({
    ...state,
    categoryList: action.categoryList,
  })),

  on(getFittingsListActionSuccess, (state, action) => ({
    ...state,
    fittingList: action.fittingList,
  })),

  on(InitializeRootPathSuccess, (state, action) => ({
    ...state,
    rootPath: action.filePath,
  })),

  on(UpdateRootPathSuccess, (state, action) => {
    localStorage.setItem(GlobalConstants.rootPath, JSON.stringify(action.updatedRootPath));
    return {
      ...state,
      updateRootPath: action.updatedRootPath,
    };
  }),

  on(initializeProjectListSuccess, (state, action) => ({
    ...state,
    projectList: action.projectList,
  })),

  on(getProcessFittingStatusSuccess, (state, action) => ({
    ...state,
    processStatus: action.status,
  })),

  on(InitializePanelListSuccess, (state, action) => ({
    ...state,
    dockablePanelList: action.dockPanelList,
  })),

  on(updateIsHangerNeededSuccess, (state, action) => ({
    ...state,
    isHangerUpdated: action.isHangerUpdated,
  })),

  on(updateIsClearanceSuccess, (state, action) => ({
    ...state,
    isClearanceUpdated: action.isClearanceUpdated,
  })),

  on(CheckInvalidShortcutsSuccess, (state, action) => ({
    ...state,
    invalidShortcut: action.invalidShortcut,
  })),

  on(GetConfigurationLocationSuccess, (state, action) => {
    localStorage.setItem(GlobalConstants.CONFIGURATION_LOCATION, JSON.stringify(action.configurationLocation));
    return {
      ...state,
      updateRootPath: action.configurationLocation,
    };
  }),

  on(GetProjectsListSuccess, (state, action) => ({
    ...state,
    projects: action.projectList,
  }))
);
