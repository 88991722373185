<sysque-frame [componentTitle]="componentTitle">
  <div class="assign-values-container">
    <div class="assign-values-container-inner">
      <div class="flex-inner-1">
        <div class="flex-inner-2">
          <div class="import-btn-inner">
            <sysque-text-button [button]="importButtonValues"></sysque-text-button>
          </div>
          <div class="content-container">
            <div class="content-c-1">
              <div class="header-c">
                <div class="th-1-c">
                  <div class="th-1-inner">
                    <div class="th-1-txt">Size</div>
                  </div>
                </div>
                <div class="th-2-c">
                  <div class="th-2-inner">
                    <div class="th-2-txt">Value</div>
                  </div>
                </div>
              </div>

              <dx-list
                [dataSource]="projectParameters"
                itemTemplate="parameterListItem"
                height="256px"
                selectionMode="none"
                [activeStateEnabled]="false"
                [focusStateEnabled]="false"
              >
                <div *dxTemplate="let item of 'parameterListItem'">
                  <div class="row-c">
                    <div class="size-lbl">
                      <div class="size-txt">Size "{{ item.id }}"</div>
                    </div>
                    <div class="size-value">
                      <modus-text-input
                        #parameterName [ngClass]="{
                          'clickable': !isContainSizes}"
                        (valueChange)="onParameterNameValueChange(parameterName.value, item)"
                        value="{{ item.value }}"
                      ></modus-text-input>
                    </div>
                  </div>
                </div>
              </dx-list>
            </div>
          </div>
          <div class="export-btn-c">
            <div class=" export-btn-inner">
             
              <sysque-text-button [button]="exportButtonValues"></sysque-text-button>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-c">
        <div class="sysque-alert">
          <sysque-alert
            [showAlertName]="true"
            [alertType]="alertType"
            [message]="warningMessage"
            [isDissmisible]="true"
            *ngIf="warningMessage"
            (closeButtonClicked)="onDismissClick()"
          ></sysque-alert>
        </div>
      </div>
      <div class="h-line"></div>
      <div class="b-inner-container">
        <div class="action-container">
          <dx-button id="apply-button" class="apply" text="Apply" (onClick)="saveData()"> </dx-button>
        </div>
      </div>
    </div>
  </div>
</sysque-frame>
