<sysque-frame [componentTitle]="componentTitle">
  <div class="content-wrapper">
    <div class="content-wrapper-table">
      <div class="content-wraper-inner">
        <table id="table-row"  class="fixed_header">
          <thead>
            <tr>
              <th class="hover-text" style="width: 90px ; padding-left: 15px;" *ngIf="isPushCheckBoxVisible">
                <span> Push <span class="tooltip-text top">Parameter will push through run</span></span>
              </th>
              <th style="width: 230px">Parameter</th>
              <th style="width: 50%">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let projectParameter  of projectParameterList  let i = index"   [ngClass]="getClassForRow(i,projectParameter)">
              <td class="icon-only" *ngIf="isPushCheckBoxVisible">
                <modus-checkbox
                  class="modus-checkbox-r"
                  style="padding-top: 3px"
                  (checkboxClick)="onPushValueChanged($event, projectParameter)"
                  [checked]="isPushValueChecked(projectParameter)"
                  [disabled]="!isPushValueEnabled(projectParameter)"
                ></modus-checkbox>
              </td>
              <td>
                <dx-select-box
                  id="target-param-{{ i }}"
                  class="chevrondownmed1"
                  [items]="revitDocParameterList"
                  [value]="revitDocParameterList[getSelectedParameter(projectParameter)]"
                  (onValueChanged)="onProjectParameterValueChanged($event, projectParameter, i)"
                  placeholder="Default"
                  displayExpr="name"
                  (onOpened)="onParameterDropDownOpened()"
                >
                  <div *dxTemplate="let data of 'dropDownButton'">
                    <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
                  </div>
                </dx-select-box>

                <dx-tooltip
                  *ngIf="projectParameter.name"
                  target="#target-param-{{ i }}"
                  position="top"
                  showEvent="mouseenter"
                  position="top"
                  hideEvent="mouseleave"
                  [hideOnOutsideClick]="false"
                >
                  <div>
                    <span>{{projectParameter.isShared ==="True" ? "Shared Parameter" : "Project Parameter"}}</span
                    ><br />
                    <span>For Categories : <span>{{projectParameter.category}}</span> </span><br />
                    <span>Group : <span>{{projectParameter.group}}</span> </span><br />
                    <span>Type : <span>{{projectParameter.type}}</span> </span><br />
                  </div>
                </dx-tooltip>
              </td>
              <td>
                <div class="form-group">
                  <input
                    class="form-control"
                    [ngClass]="{
                    'clickable': setClassForMultipleValueText(projectParameter)}"
                    type="text"
                    value="{{getParameterTextValue(projectParameter)}}"
                    (keyup)="onParameterValueChange($event,i)"
                  />
                </div>
              </td>

              <td class="icon-only">
                <div class="dropdown">
                  <button
                    style="border: none; background-color: transparent"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img alt="Delete" src="assets/img/Circle.png" />
                  </button>
                  <div style="width: 100px" class="dropdown-menu dropdown-menu-right">
                    <button
                      class="dropdown-item context-menu"
                      href="#"
                      (click)="modifyProjectParameterSizesData(projectParameter)"
                    >
                      <img class="menu-radio-btn" src="assets/img/list-bulleted.png" alt="modify" /> Modify
                    </button>
                    <button
                      class="dropdown-item context-menu"
                      href="#"
                      (click)="deleteProjectParameter(projectParameter)"
                    >
                      <img class="menu-radio-btn" src="assets/img/list-bulleted.png" alt="delete" /> Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="add-button-container">
        <sysque-text-button [button]="addRowButtonValues"></sysque-text-button>
        <div class="add-project-button-container">
          <dx-button (onClick)="addNewProjectParameter()">
            <img style="margin-right: 10px" src="assets/img/addCircle.png" alt="logo" />
            Add project Parameter
          </dx-button>
        </div>
      </div>
    </div>
  </div>

  <div class="window-alert" *ngIf="warningMessage">
    <sysque-alert
      [alertType]="alertType"
      [message]="warningMessage"
      [isDissmisible]="true"
      [showAlertName]="true"
      (closeButtonClicked)="onDismissClick()"
    ></sysque-alert>
  </div>

  <div class="bottom-container">
    <dx-button id="apply-button" class="apply-button" text="Apply" (onClick)="saveData()"></dx-button>
  </div>
</sysque-frame>
