<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container">
    <div class="browse-container" style="margin-top: 20px; margin-left: 10px">
      <dx-button class="browse-button" (onClick)="onBrowseClick()">Browse</dx-button>
      <label class="file-name-label">{{ validFileName }}</label>
    </div>
    <div class="schedule-name-container">
      <input
        type="text"
        [(ngModel)]="scheduleName"
        placeholder="{{ data.scheduleName }}"
        class="schedule-name-input"
        pattern="[a-zA-Z].*"
        (input)="onUserInputChanged()"
      />
    </div>
    <div class="sysque-alert">
      <sysque-alert
        [showAlertName]="true"
        [alertType]="alertType"
        [message]="warningMessage"
        [isDissmisible]="false"
        *ngIf="warningMessage"
      ></sysque-alert>
    </div>
  </div>
  <div class="bottom-container" [ngClass]="{ 'bottom-container-with-alert': warningMessage }">
    <dx-button
      id="import-button"
      class="import-button"
      [ngClass]="{ 'disabled-import-button': isImportButtonDisabled }"
      text="{{ data.buttonName }}"
      (onClick)="importSchedule()"
      [disabled]="isImportButtonDisabled"
    >
    </dx-button>
  </div>
</sysque-frame>
