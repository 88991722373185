export class CurateSearchActionsNames {

    static getResultInformationFromTreeViewFolderItems = (): string => '[Tree view] Get result information from tree view folder';

    static getResultInformationFromTreeViewFolderItemsSuccess = (): string => '[Tree view] Get result information from tree view folder success';

    static UpdateTreeViewNodeOnExpansionChanged = (): string => '[Tree view] Update tree view node on expansion changed';

    static UpdateTreeViewNodeOnExpansionChangedSuccess = (): string => '[Tree view] Update tree view node on expansion changed success';
  
    static getTreeViewRootDirectory = (): string => '[Tree view] Get tree view root directory action';

    static getTreeViewRootDirectorySuccess = (): string => '[Tree view] Get tree view root directory action success';
  
}