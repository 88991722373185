import { Component } from '@angular/core';
import { validateDevExtremeLicense } from 'devextreme-validator';

validateDevExtremeLicense();

@Component({
  selector: 'mep-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'sysque-electrical';
}
