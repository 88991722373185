import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RootpathService {
  setRootPathChange(value: string) {
    localStorage.setItem('rootPath', value);
  }

  getRootPathChange(): string {
    return localStorage.getItem('rootPath') || '';
  }
}
