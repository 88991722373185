<div class="header">
    <div class="title">{{buttonInfo.title}}</div>
    <div class="option-btn-container" *ngIf="buttonInfo.hasOptionButton">
        <modus-button button-style="borderless" (click)="onOptionButtonClick()" [disabled]="!buttonInfo.enabled">
            <img src="assets/img/option-button.png" style="transform: rotate(90deg)" alt="logo" />
        </modus-button>
    </div>
</div>
<div class="toggle-switch">
    <label class="switch">
        <input type="checkbox" (click)="onSwitchClick(buttonInfo)" [(ngModel)]="buttonInfo.enabled" />
        <span class="slider"></span>
    </label>
    <div class="text-wrapper">
        <span class="switch-state">{{ buttonInfo.enabled ? buttonInfo.labelForEnabled : buttonInfo.labelForDisabled
            }}</span>
    </div>
</div>