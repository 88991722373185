import { Component, OnInit, ViewChild } from '@angular/core';
import { SysquePopupAdDirective } from '../app-shell/sysque-popup-adDirective';
import { ActivatedRoute } from '@angular/router';
import { ShowSysquePopupAction } from '../+state/toolbelt.actions';
import { Store } from '@ngrx/store';
import { SysquePopupConfig } from '../models/sysque-popup-config';
import { createDialog } from '../popup-dialog-factory/popup-dialog.facade';
import { dialogMap } from '../constants/global-constants';
import { selectSysquePopup } from '../+state/tool-belt.selector';

@Component({
  selector: 'sysque-popup-routing',
  templateUrl: './popup-routing.component.html',
  styleUrls: ['./popup-routing.component.scss'],
})
export class PopupRoutingComponent implements OnInit {

  @ViewChild(SysquePopupAdDirective, { static: true }) adPopupDirective!: SysquePopupAdDirective;

  constructor(private route: ActivatedRoute, private store: Store) {}
  
  ngOnInit(): void {
    this.store.select(selectSysquePopup).subscribe((comp) => this.resolvePopupVisibility(comp));

    this.showSysquePopup(this.route.snapshot.paramMap.get('dialog-name'));
  }

  resolvePopupVisibility(config: SysquePopupConfig) {
    if (config) {

      const container = this.adPopupDirective.viewContainerRef;
      container.clear();
      const compRef = container.createComponent(config.component);
      if (compRef) {
        compRef.instance['data'] = config.data;
        compRef.instance['dragEnabled'] = config.dragEnabled;
        compRef.instance['height'] = config.height;
        compRef.instance['width'] = config.width;
        compRef.instance['hideOnOutsideClick'] = config.hideOnOutsideClick;
        compRef.instance['showCloseButton'] = config.showCloseButton;
        compRef.instance['componentTitle'] = config.componentTitle;
        compRef.instance['visible'] = config.visible;
        compRef.instance['close'].subscribe(() => {
          this.store.dispatch(ShowSysquePopupAction({ sysquePopupConfig: null }));
        });
      }
    }
  }

  showSysquePopup(dialogName: string) {
    const itemName = this.getItemNameBasedOnActionName(dialogName);
    if(!itemName){return;}
    let sysquePopupConfig = new SysquePopupConfig();
    let dialog = createDialog(sysquePopupConfig, itemName);
    if (dialog === null) {
      return;
    }
    sysquePopupConfig && this.store.dispatch(ShowSysquePopupAction({ sysquePopupConfig: sysquePopupConfig }));
  }

  getItemNameBasedOnActionName(actionName: string) {
    if (dialogMap.has(actionName)) {
      return dialogMap.get(actionName);
    }
    return null;
  }
}
