<div *ngIf="!fileName; else fileLabel">
  <modus-button button-style="borderless" (click)="onAttachFileClicked()">
    <img src="assets/img/PaperClip.png" alt="logo" />
    <span class="attach-pdf-button">{{attachPdf}}</span>
  </modus-button>
</div>
<ng-template #fileLabel>
  <div class="file-label">
    <modus-button button-style="borderless" class="pdf-button" (click)="onAttachFileClicked()">
      <img src="assets/img/File.png" alt="logo" />
      <span class="file-name-button">{{fileName}}</span>
    </modus-button>
    <modus-button button-style="borderless" class="edit-button" (click)="onOpenPdf()">
      <img src="assets/img/Edit-Black.png" alt="logo" />
    </modus-button>
  </div>
</ng-template>