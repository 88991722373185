<div
  id="right-click-target"
  (contextmenu)="onRightClick($event)"
  style="margin: 0; padding: 0; display: flex; flex-direction: column; min-height: 100%"
  [ngClass]="{
    's-panel-frame': true,
    'addFitting-mode':  true ,
  }"
>
  <div style="display: flex; height: 30px">
    <sysque-select-system
      (valueChanged)="onValueChanged($event)"
      [systemInfo$]="systemInfo$"
      [selectedSystem]="addFittingState.selectedSystem"
      [panelName]="'addFittingPanel'"
    ></sysque-select-system>
    <div class="group-context-menu">
      <div class="group-btn">
        <modus-button
          id="system-button"
          button-style="borderless"
          (click)="systemContextMenu.contextMenuVisibility($event)"
        >
          <img alt="logo" src="assets/img/Group.png" style="transform: rotate(-90deg)" />
        </modus-button>
      </div>
      <sysque-context-menu
        #systemContextMenu
        target="#system-button"
        (contextMenuItemSelected)="handleContextMenuItem($event)"
        (contextMenuOpened)="closeOtherContextMenu(systemContextMenu)"
        [menuItems]="menuItems"
      ></sysque-context-menu>
    </div>
  </div>

  <div style="display: flex; height: 30px">
    <sysque-select-category
      (valueChanged)="onCategoryChanged($event)"
      [categories$]="categoryList$"
      [selectedCategory]="selectedCategory"
    ></sysque-select-category>
    <div class="group-context-menu">
      <div class="group-btn">
        <modus-button
          id="category-button"
          button-style="borderless"
          (click)="categoryContextMenu.contextMenuVisibility($event)"
        >
          <img alt="logo" src="assets/img/Group.png" style="transform: rotate(-90deg)" />
        </modus-button>
      </div>
      <sysque-context-menu
        #categoryContextMenu
        target="#category-button"
        (contextMenuItemSelected)="handleCategoryContextMenuItem($event)"
        (contextMenuOpened)="closeOtherContextMenu(categoryContextMenu)"
        [menuItems]="categoryMenuItems"
      ></sysque-context-menu>
    </div>
  </div>

  <ng-container style="height: 350px">
    <sysque-category-scope
      [key]="'adf'"
      [addFittingMode]="true"
      [selectedSystem]="addFittingState.selectedSystem"
      [manageMode]="false"
      [categoryList$]="categoryList$"
      [fittingList]="fittingList$ | async"
      [selectedCategory]="addFittingState.selectedCategory"
      [key]="'adf'"
      (categoryChanged)="onCategoryChanged($event)"
      (showInEnclosingFolder)="showInEnclosingFolder($event)"
      (copiedItemListChanged)="updateCopiedItemList($event)"
    >
    </sysque-category-scope>

    <dx-context-menu
      *ngIf="showRightClickMenu && copiedItems.length > 0"
      class="right-context-menu"
      [dataSource]="rightClickMenuItems"
      target="#right-click-target"
      (onShowing)="onContextMenuShowing($event)"
      (onItemClick)="onContextMenuItemClick($event)"
    >
    </dx-context-menu>
  </ng-container>

  <div
    [ngClass]="{
   
    'manage-mode-position': true,
    }"
  >
    <sysque-manage-btn (notify)="manageModeClicked()"></sysque-manage-btn>
    <sysque-root-path-selector *ngIf="showRootPath" [manageMode]="false"></sysque-root-path-selector>
  </div>
</div>
