import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ToolBeltState } from './tool-belt.reducer';
import * as fromToolBeltReducer from './tool-belt.reducer';

export const selectElectricalToolBeltState = createFeatureSelector<ToolBeltState>(fromToolBeltReducer.toolBeltFeatureKey);

export const selectToolBeltItems = createSelector(selectElectricalToolBeltState, (state) => state.accordionGroups.filter((s) => s.apps.includes('Electrical')));

export const selectSystemInfo = createSelector(selectElectricalToolBeltState, (state) => state.systemInfo);

export const selectCategories = createSelector(selectElectricalToolBeltState, (state) => state.categoryList);

export const selectFittings = createSelector(selectElectricalToolBeltState, (state) => state.fittingList);

export const selectHangerSpecifications = createSelector(selectElectricalToolBeltState, (state) => state.hangerSpecifications);

export const selectSysquePopup = createSelector(selectElectricalToolBeltState, (state) => state.sysquePopupConfig);

export const selectAddFittingMode = createSelector(selectElectricalToolBeltState, (state) => state.addFittingMode);

export const selectManageMode = createSelector(selectElectricalToolBeltState, (state) => state.manageMode);

export const selectCurateSearchLastIndex = createSelector( selectElectricalToolBeltState, (state) => state.curateSearchLastIndex);

export const selectHideGetMoreCurateSearchResult = createSelector( selectElectricalToolBeltState, (state) => state.hideGetMoreCurateSearchResult);

export const selectRootPath = createSelector(selectElectricalToolBeltState, (state) => state.rootPath); 

export const selectDockableProjectList = createSelector(selectElectricalToolBeltState, (state) => state.projectList);

export const selectProcessFittStatus = createSelector(selectElectricalToolBeltState, (state) => state.processStatus);

export const selectDockablePanelList = createSelector(selectElectricalToolBeltState, (state) => state.dockablePanelList);

export const selectHangerNeeded = createSelector(selectElectricalToolBeltState, (state) => state.isHangerUpdated);

export const selectIsClearance = createSelector(selectElectricalToolBeltState, (state) => state.isClearanceUpdated);

export const selectOnlyFitting = createSelector(selectElectricalToolBeltState, (state) => state.isProcessFittingsOnly);

export const selectInvalidShortcut = createSelector(selectElectricalToolBeltState, (state) => state.invalidShortcut);

export const selectConfigurationLocation = createSelector(selectElectricalToolBeltState, (state) => state.configurationLocation);

export const getProjectList = createSelector(selectElectricalToolBeltState, (state) => state.projects);