export class ApplyPanelDataActionsNames {
    static InitializeProjectData = (): string => '[ApplyPanelData] InitializeProjectData';
    static InitializeProjectDataSuccess = (): string => '[ApplyPanelData] InitializeProjectData Success';
    static ValidatePanelCircuitData = (): string => '[ApplyPanelData] ValidatePanelCircuitData';
    static ValidatePanelCircuitDataSuccess = (): string => '[ApplyPanelData] ValidatePanelCircuitData Success';
    static ChangeApplyPanelDialogSize = (): string => '[ApplyPanelData] ChangeApplyPanelDialogSize';
    static ChangeApplyPanelDialogSizeSuccess = (): string => '[ApplyPanelData] ChangeApplyPanelDialogSize Success';
    static ApplyPanelDataClick = (): string => '[ApplyPanelData] ApplyPanelDataClick';
    static ApplyPanelDataClickSuccess = (): string => '[ApplyPanelData] ApplyPanelDataClick Success';
}
