<div class="info-container">
  <div class="info-sub-container">
    <div class="info-sub-container2">
      <div class="title-container">
        <div class="title">Family Information</div>
      </div>
      <div class="content-container">
        <div class="left-panel">
          <div class="panel-l1">
            <div class="panel-l2"></div>
            <div class="panel-l3"></div>
            <div class="panel-l4"></div>
          </div>
          <div class="left-container">
            <div class="label-item">{{title}}</div>
            <div class="image-and-properties-container">
              <div class="image-container">
                <img [src]="image" alt="item image" class="image" />
              </div>
              <sysque-properties-list class="properties-list-container"
                [propertiesListItems]="propertiesListItems"
                (valueChanged)="onPropertiesValueChanged($event)">
              </sysque-properties-list>
            </div>
            <div class="service-type-container">
              <div *ngIf="isLengthVisible">
                <sysque-number-box-options-line
                  [numberBoxItems]="lengthItems" 
                  [showSpinButtons]="lengthSpinButtons"
                  (valueChanged)="onLengthValueChanged($event)">
                </sysque-number-box-options-line>
              </div>
              <div *ngIf="isServiceTypeVisible">
                <sysque-dropdown-service-type 
                  [data]="serviceTypeData" 
                  [serviceTypeName]="serviceTypeName"
                  [placeholder]="serviceTypePlaceholder"
                  (valueChanged)="onServiceTypeValueChanges($event)"
                  (openContextMenu)="contextMenu.contextMenuVisibility($event)">
                </sysque-dropdown-service-type>
                <sysque-context-menu
                  #contextMenu
                  target="#modus-button"
                  (contextMenuItemSelected)="handleContextMenuItem($event)"
                  [menuItems]="menuItems"
                ></sysque-context-menu>
                <sysque-line-graphics *ngIf="isLineGraphicsDialogOpen" [config]="lineGraphicsDialogConfig"></sysque-line-graphics>
                <sysque-create-service-type *ngIf="isCreateServiceTypeDialogOpen" [config]="createServiceTypeDialogConfig"></sysque-create-service-type>
                <sysque-delete-service-type *ngIf="isDeleteServiceTypeDialogOpen" [config]="deleteServiceTypeDialogConfig"></sysque-delete-service-type>
              </div>
            </div>
            <div class="angle-container">
              <div *ngIf="isAngleVisible">
                <sysque-number-box-options-line
                  [numberBoxItems]="angleItems" 
                  [showSpinButtons]="angleSpinButtons"
                  (valueChanged)="onAngleValueChanged($event)">
                </sysque-number-box-options-line>
              </div>
            </div>
            <div class="connectors-container">
              <div *ngIf="areConnectorsVisible">
                <sysque-dropdown-connectors 
                  [title]="connectorsTitle" 
                  [connectors]="connectorsItems"
                  (valueChanged)="onConnectorValueChanged($event)">
                </sysque-dropdown-connectors>
              </div>
              <div *ngIf="isWallTypeVisible">
                <sysque-switch-button 
                  [buttonInfo]="wallTypeButtonInfo"
                  (switchClicked)="onWallTypeClicked($event)">
                </sysque-switch-button>
              </div>
              <div *ngIf="isHangerVisible">
                <sysque-switch-button 
                  [buttonInfo]="hangerButtonInfo"
                  (switchClicked)="onHangerSwitchClicked($event)">
                </sysque-switch-button>
              </div>
            </div>
            <div class="support-discipline-container">
              <div *ngIf="isSupportDisciplineVisible">
                <sysque-text-area-description 
                  [label]="supportDisciplineLabel"
                  [description]="supportDiscipline"
                  [height]="30"
                  (valueChanged)="onSupportDisciplineValueChanged($event)">
                </sysque-text-area-description>
              </div>
            </div>
            <div class="instance-description-container">
              <sysque-text-area-description 
                [label]="instanceDescriptionLabel"
                [description]="instanceDescription"
                (valueChanged)="onInstanceDescriptionValueChanged($event)">
              </sysque-text-area-description>
            </div>
            <div class="type-description-container">
              <sysque-text-area-description 
                [label]="typeDescriptionLabel"
                [description]="typeDescription"
                (valueChanged)="onTypeDescriptionValueChanged($event)">
              </sysque-text-area-description>
            </div>
            <div class="project-data-container">
              <sysque-switch-button 
                [buttonInfo]="projectDataButtonInfo" (switchClicked)="onProjectDataSwitchClicked($event)" (optionButtonClicked)="onProjectDataButtonClicked()">
              </sysque-switch-button>
            </div>
          </div>
        </div>
        <div class="right-panel">
          <div class="panel-l1">
            <div class="panel-l2"></div>
            <div class="panel-l3"></div>
            <div class="panel-l4"></div>
          </div>
          <div class="right-container">
            <sysque-datagrid-size-range #sizeGrid 
              [dataGrid]="dataGrid"
              (selectedItems)="onSizeRangeSelectedItems($event)">
            </sysque-datagrid-size-range>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sysque-alert">
    <sysque-alert 
            [showAlertName]="true" 
            [alertType]="alertType" 
            [message]="warningMessage" 
            [isDissmisible]="true"
            *ngIf="warningMessage"
            (closeButtonClicked)="onAlertCloseButtonClick()">
    </sysque-alert>
  </div>
  <div class="action-container">
    <dx-button 
      id="apply-button" 
      class="done" 
      (onClick)="doneClick()" 
      text="Done">
    </dx-button>
  </div>
</div>