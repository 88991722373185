export class FileOrFolder{
    isFolder!: boolean;
    fullPath!: string;
    scheduleID!: string;
    image!: string;
    label!: string;
    fittingEntryID!: number;
    fittingIDNumber!: string;
    manufacturerName!: string;
    fileOrDirectory!: FileOrFolder[];
}