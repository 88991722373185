<div class="root-path-container">
  <div class="rp-1">
    <div class="rp-2">
      <div class="rp-3">Root Path</div>
    </div>
  </div>
  <div [ngClass]="{ 'rp-txt-1': true, 'rp-txt-1-manage-mode': manageMode ? true : false }">
    <div [ngClass]="{ 'rp-txt-2': true, 'rp-txt-2-manage-mode': manageMode ? true : false }">
      <div
        id="rootapth"
        disabled="true"
        class="root-path-text"
        label=""
        placeholder="C:\ProgramData\Building Data\Support">
        {{ rootPath$ | async }}
        <span class="tooltiptext">{{ rootPath$ | async }}</span>
      </div>
    </div>
    <div class="group-btn-container">
      <modus-button button-style="borderless" (click)="getRootPath()">
        <img src="assets/img/Group.png" alt="logo" />
      </modus-button>
    </div>
  </div>
</div>