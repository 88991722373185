import { Component, Input } from '@angular/core';

@Component({
  selector: 'sysque-progress-bar',
  templateUrl: './sysque-progress-bar.component.html',
  styleUrls: ['./sysque-progress-bar.component.scss'],
})
export class SysqueProgressBarComponent {
  @Input() actionName: string;
  @Input() actionText: string;
  @Input() value: string;

  format() {
    return '';
  }
}
