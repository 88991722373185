import { createAction, props } from "@ngrx/store";
import { ImportScheduleActionsNames } from "./import-schedule-action-names";

export const ChangeDialogHeight = createAction(
    ImportScheduleActionsNames.ChangeDialogHeight(),
    props<{ height: number }>()
);

export const ChangeDialogHeightSuccess = createAction(
    ImportScheduleActionsNames.ChangeDialogHeightSuccess(),
    props<{ response: string }>()
);
  
export const BrowseClick = createAction(
    ImportScheduleActionsNames.BrowseClick()
);
  
export const BrowseClickSuccess = createAction(
    ImportScheduleActionsNames.BrowseClickSuccess(),
    props<{ response: string }>()
);

export const ImportScheduleClick = createAction(
    ImportScheduleActionsNames.ImportScheduleClick(),
    props<{ scheduleName: string }>()
);

export const ImportScheduleClickSuccess = createAction(
    ImportScheduleActionsNames.ImportScheduleClickSuccess(),
    props<{ response: string }>()
);