<div
  id="right-click-target-id-{{ key }}"
  (contextmenu)="onRightClick($event)"
  style="margin: 0; padding: 0; display: flex; flex-direction: column; min-height: 100%"
  [ngClass]="{
    's-panel-frame': true,
    'addFitting-mode': false,
    'manage-mode': true
  }"
>
  <div style="display: flex; height: 30px">
    <sysque-select-system
      (valueChanged)="onValueChanged($event)"
      [systemInfo$]="systemInfo$"
      [selectedSystem]="system"
      [panelName]="'manageModePanel'"
    ></sysque-select-system>
  </div>

  <ng-container style="height: 350px">
    <sysque-select-category
      (valueChanged)="onCategoryChanged($event)"
      [categories$]="categoryList$"
      [selectedCategory]="defaultCategory"
    ></sysque-select-category>

    <sysque-category-scope
      [key]="key"
      [addFittingMode]="true"
      [manageMode]="true"
      [categoryList$]="categoryList$"
      [fittingList]="fittingList$ | async"
      (categoryChanged)="onCategoryChanged($event)"
    >
    </sysque-category-scope>

    <dx-context-menu
      class="context-menu"
      *ngIf="this.key === 'dest' && copiedItems.length > 0"
      [dataSource]="destinationMenuItems"
      target="#right-click-target-id-{{ key }}"
      (onShowing)="onContextMenuShowing($event)"
      (onItemClick)="onContextMenuItemClick($event)"
    >
    </dx-context-menu>
  </ng-container>

  <div
    [ngClass]="{
    'std-position': true,
    'manage-mode-position':  true,
    }"
  >
    <sysque-root-path-selector *ngIf="showRootPath" [manageMode]="false"></sysque-root-path-selector>
  </div>
</div>
