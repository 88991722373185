export enum ApplyConduitIdReturnCodes
    {
        Success = 0,
        NoSelection = 1,
        NoConduitSelection = 2,
        ValidSelection = 3
    };

    export enum ApplyEquipmentDeviceIdReturnCodes
    {
        Success = 10,
        NoSelection = 11,
        NoEquipmentDeviceSelection = 12,
        ValidSelection = 13
    };

