<div [ngClass]="{ 'border-style': isBordered, 'no-border-style': !isBordered }">
  <dx-button
    class="button-style"
    [width]="width"
    [disabled]="isDisabled"
    [type]="isPrimary ? 'default' : 'normal'"
    (onClick)="onButtonClick()"
  >
    <img *ngIf="hasImage" class="button-image" [src]="imageSrc" alt="logo" />
    {{ name }}
  </dx-button>
</div>
