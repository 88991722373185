<div class="info-container">
  <div class="info-sub-container">

    <div class="info-sub-container2">
      <div class="title-container">
        <div class="title">Family Information</div>
      </div>
      <div class="content-container">
        <div class="basic-panel">
          
          <div class="tab-panel-container">
            <dx-tab-panel class="diagram">

              <dxi-item title="Attributes">
                <div class="panel-l1">
                  <div class="panel-l2">
                    <div class="panel-l3">
                      <div class="panel-l4">
                        <div class="label-item">{{title}}</div>
                        <div class="attributes-basic-container">
                          <div class="attributes-left-container">
                            <img src="assets/img/HangerDiagram.png" alt="item image" class="attributes-image" />
                            <sysque-properties-list class="properties-list-container" 
                              [propertiesListItems]="propertiesListItems"
                              (valueChanged)="onPropertiesValueChanged($event)">
                            </sysque-properties-list>
                          </div>
                          <div class="attributes-right-container">
                            <div *ngIf="isConduit">
                              <sysque-number-box-options-line 
                                [numberBoxItems]="maxDiameterItems" 
                                [showSpinButtons]="maxDiameterSpinButtons"
                                (valueChanged)="onMaxDiameterChanged($event)">
                              </sysque-number-box-options-line>
                            </div>
                            <div *ngIf="!isConduit">
                              <sysque-number-box-options-line 
                                [numberBoxItems]="maxWidthItems" 
                                [showSpinButtons]="maxWidthSpinButtons"
                                (valueChanged)="onMaxWidthChanged($event)">
                              </sysque-number-box-options-line>
                              <sysque-number-box-options-line 
                                [numberBoxItems]="maxDepthItems" 
                                [showSpinButtons]="maxDepthSpinButtons"
                                (valueChanged)="onMaxDepthChanged($event)">
                              </sysque-number-box-options-line>
                            </div>
                            <sysque-number-box-options-line 
                              [numberBoxItems]="rodDiameterItems" 
                              [showSpinButtons]="rodDiameterSpinButtons"
                              (valueChanged)="onRodDiameterChanged($event)">
                            </sysque-number-box-options-line>
                            <div class="instance-description-container">
                              <sysque-text-area-description 
                                [label]="instanceDescriptionLabel" 
                                [description]="instanceDescription"
                                [height]="instanceDescriptionHeight"
                                [width]="instanceDescriptionWidth"
                                (valueChanged)="onInstanceDescriptionValueChanged($event)">
                              </sysque-text-area-description>
                            </div>
                            <div class="type-description-container">
                              <sysque-text-area-description 
                                [label]="typeDescriptionLabel" 
                                [description]="typeDescription"
                                [height]="typeDescriptionHeight"
                                [width]="typeDescriptionWidth"
                                (valueChanged)="onTypeDescriptionValueChanged($event)">
                              </sysque-text-area-description>
                            </div>
                            <div  [ngClass]="{
                              'project-data-container-conduit': isConduit,
                              'project-data-container-cable-tray': isCableTray,
                            }">
                              <sysque-switch-button 
                                [buttonInfo]="projectDataButtonInfo"
                                (switchClicked)="onProjectDataSwitchClicked($event)"
                                (optionButtonClicked)="onProjectDataButtonClicked()">
                              </sysque-switch-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dxi-item>
              
              <dxi-item title="Placement">
                <div class="panel-l1">
                  <div class="panel-l2">
                    <div class="panel-l3">
                      <div class="panel-l4">
                        <div class="label-item">{{title}}</div>
                        <div class="placement-basic-container">
                          <div class="placement-left-container">
                            <img src="assets/img/HangerPlacementDiagram.png" alt="item image" class="placement-image" />
                          </div>
                          <div class="placement-right-container">
                            <sysque-number-box-options-line 
                              [numberBoxItems]="hangerSpacingItems" 
                              [showSpinButtons]="hangerSpacingSpinButtons"
                              (valueChanged)="onHangerSpacingChanged($event)">
                            </sysque-number-box-options-line>
                            <sysque-number-box-options-line 
                              [numberBoxItems]="distanceFromFittingsItems" 
                              [showSpinButtons]="distanceFromFittingsSpinButtons"
                              (valueChanged)="onDistanceFromFittingsChanged($event)">
                            </sysque-number-box-options-line>
                            <sysque-number-box-options-line 
                              [numberBoxItems]="distanceFromCouplingsItems" 
                              [showSpinButtons]="distanceFromCouplingsSpinButtons"
                              (valueChanged)="onDistanceFromCouplingsChanged($event)">
                            </sysque-number-box-options-line>
                            <sysque-number-box-options-line 
                              [numberBoxItems]="distanceFromEdgeOfBeamItems" 
                              [showSpinButtons]="distanceFromEdgeOfBeamSpinButtons"
                              (valueChanged)="onDistanceFromEdgeOfBeamChanged($event)">
                            </sysque-number-box-options-line>
                            <sysque-number-box-options-line 
                              [numberBoxItems]="rodLengthItems" 
                              [showSpinButtons]="rodLengthSpinButtons"
                              (valueChanged)="onRodLengthChanged($event)">
                            </sysque-number-box-options-line>
                            <div class="d-flex mb-4 align-items-center">
                              <label class="flex-1 mb-0" for="color-picker">Hanger Color</label>
                              <div class="color-toggle d-flex flex-row flex-1 border">
                                <div class="d-flex align-items-center">
                                  <input #ignoredInput class="color-picker-input"
                                    [(colorPicker)]="currentColor"
                                    [style.background]="currentColor"
                                    (colorPickerChange)="onColorChange($event)"
                                    [(cpToggle)]="toggle"
                                  />
                                </div>
                                <div class="toggle-color-picker" #ignoredButton (click)="toggle=!toggle">
                                  <img class="mt-2" src="assets/img/ColorPickerIcon.png" alt="Color Picker">
                                </div>
                              </div>
                            </div>
                            <div class="no-override-container">
                              <sysque-switch-button 
                                [buttonInfo]="noOverrideButtonInfo"
                                (switchClicked)="onNoOverrideButtonClicked($event)">
                              </sysque-switch-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dxi-item>

              <dxi-item title="Nominal Sizes">
                <div class="panel-l1">
                  <div class="panel-l2">
                    <div class="panel-l3">
                      <div class="panel-l4">
                        <div class="label-item">{{title}}</div>
                        <div class="nominal-sizes-basic-container">
                          <div class="nominal-sizes-label">Available Nominal Sizes</div>
                          <div class="nominal-sizes-bagdes-container">
                            <div *ngFor="let item of nominalSizesItems" class="nominal-sizes">
                              <span class="badge badge-light">{{ getItemLabelWithUnit(item) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dxi-item>

            </dx-tab-panel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sysque-alert">
    <sysque-alert 
      [showAlertName]="true" 
      [alertType]="alertType" 
      [message]="warningMessage" 
      [isDissmisible]="true"
      *ngIf="warningMessage" 
      (closeButtonClicked)="onAlertCloseButtonClick()">
    </sysque-alert>
  </div>
  <div class="action-container">
    <dx-button 
      id="apply-button" 
      class="done" 
      (onClick)="doneClick()" 
      text="Done">
    </dx-button>
  </div>
</div>