 <dx-tab-panel
  #dxTabPanel
  [dataSource]="content"
  [selectedIndex]="0"
  (onTitleClick)="titleClick($event)"
>
  <div *dxTemplate="let item of 'title'">
    <span>{{ item.name }}</span>
  </div>
  <div *dxTemplate="let item of 'item'">
    <ng-container *ngComponentOutlet="
        item.component;
        inputs: item.inputs;
      " />
    </div>
</dx-tab-panel> 



