export class ToolbeltActionsNames {
  static InitializeToolbeltAccordionGroups = (): string => '[Toolbelt] InitializeToolbeltAccordionGroups';

  static InitializeToolbeltAccordionGroupsSuccess = (): string =>
    '[Toolbelt] InitializeToolbeltAccordionGroups Success';

  static InitializeSystemInfo = (): string => '[Toolbelt] InitializeSystemInfo';

  static InitializeSystemInfoSuccess = (): string => '[Toolbelt] InitializeSystemInfo Success';

  static GetProjectsList = (): string => '[Toolbelt] GetProjectsList';

  static GetProjectsListSuccess = (): string => '[Toolbelt] GetProjectsList Success';

  static UpdateSystemInfo = (): string => '[Toolbelt] UpdateSystemInfo';

  static UpdateSystemInfoSuccess = (): string => '[Toolbelt] UpdateSystemInfo Success';

  static InitializeHangerSpecifications = (): string => '[Toolbelt] InitializeHangerSpecifications';

  static InitializeHangerSpecificationsSuccess = (): string => '[Toolbelt] InitializeHangerSpecifications Success';

  static SetInfoDialogVisibleAction = (): string => '[Toolbelt] SetInfoDialogVisibleActon Success';

  static ShowSysquePopupAction = (): string => '[Toolbelt] ShowSysquePopupAction Success';

  static setAddFittingModeAction = (): string => '[Toolbelt] show Add Fitting Panel';

  static setManageModeAction = (): string => '[Toolbelt] Set Manage Panel';

  static sendPopupRoutingAction = (): string => '[Toolbelt] Send popup routing action';

  static sendPopupRoutingActionSuccess = (): string => '[Toolbelt] Send popup routing action success';

  static onAddProjectClickAction = (): string => '[Toolbelt] on add project click action';

  static onAddProjectClickActionSuccess = (): string => '[Toolbelt] on add project click action success';

  static getCategoryListAction = (): string => '[Toolbelt] Get category list action';

  static getCategoryListActionSuccess = (): string => '[Toolbelt] Get category list action success';

  static sendClosePopupAction = (): string => '[Add Fittings] Send close popup action';

  static sendClosePopupActionSuccess = (): string => '[Add Fittings] Send close popup action success';

  static getFittingsListAction = (): string => '[Toolbelt] Get fittings list action';

  static UpdateRootPath = (): string => '[Toolbelt] UpdateRootPath';

  static UpdateRootPathSuccess = (): string => '[Toolbelt] UpdateRootPath Success';

  static InitializeRootPath = (): string => '[Toolbelt] InitializeRootPath';

  static InitializeRootPathSuccess = (): string => '[Toolbelt] InitializeRootPath Success';

  static getFittingsListActionSuccess = (): string => '[Toolbelt] Get fittings list action success';

  static InitializeProjectList = (): string => '[Toolbelt] InitializeProjectList';

  static InitializeProjectListSuccess = (): string => '[Toolbelt] InitializeProjectListSuccess Success';

  static InitializeWireFill = (): string => '[Toolbelt] InitializeWireFill';

  static InitializeWireFillSuccess = (): string => '[Toolbelt] InitializeWireFillSuccess Success';

  static getProcessFittingStatus = (): string => '[Toolbelt] getProcessFittingStatus';

  static getProcessFittingStatusSuccess = (): string => '[Toolbelt] getProcessFittingStatusSuccess Success';

  static InitializePanelListSuccess = (): string => '[Toolbelt] InitializePanelListSuccess Success';

  static updateIsHanger = (): string => '[Toolbelt] updateIsHanger';

  static updateIsHangerSuccess = (): string => '[Toolbelt] updateIsHangerSuccess Success';

  static updateIsClearance = (): string => '[Toolbelt] updateIsClearance';

  static updateIsClearanceSuccess = (): string => '[Toolbelt] updateIsClearanceSuccess Success';

  static checkInvalidShortcuts = (): string => '[Toolbelt] Check for invalid shortcuts';

  static checkInvalidShortcutsSuccess = (): string => '[Toolbelt] Check for invalid shortcuts success';

  static getConfigurationLocation = (): string => '[Toolbelt] Get configuration location';

  static getConfigurationLocationSuccess = (): string => '[Toolbelt] Get configuration location success';
}
