<div [ngClass]="{ 'add-fittings-container-p': true, 'manage-container-p': true }">
  <div [ngClass]="{ 'add-fittings-container': true, 'manage-container': true }">
    <div>
      <div class="container-label">
        <label class="label">Source System</label>
      </div>
      <sysque-manage-src-mode-panel-frame
        [key]="'src'"
        [isSystemSource]="true"
        [showRootPath]="true"
        (onCopyCategoryToSystem)="onCopyCategoryToDestSystem()"
      ></sysque-manage-src-mode-panel-frame>
    </div>
    <div *ngIf="showDialog" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">Replace Item</p>
          <p class="dialog-body">{{ popUpMsgContent }}</p>
        </div>

        <span *ngIf="!isCopyCategory">
          <modus-checkbox
            class="replaceCheckBox"
            (checkboxClick)="onReplaceAll($event)"
            [checked]="replaceAll"
            [label]="'Do this for additional occurrences'"
          ></modus-checkbox>
        </span>
        <div class="button-grid">
          <button class="button-cancel" (click)="onCancelClick()">Cancel</button>
          <button class="button-proceed" (click)="onReplaceClick()">Proceed</button>
        </div>
      </dx-dialog>
    </div>

    <div *ngIf="showHangerAlert" class="loading-overlay">
      <dx-dialog>
        <div class="dialog-content">
          <p class="dialog-title">Specification Details Missing</p>
          <p class="dialog-body">{{ popUpMsgContent }}</p>
        </div>

        <div class="button-grid">
          <button class="button-cancel" (click)="onNoClick()">No</button>
          <button class="button-proceed" (click)="onYesClick()">Yes</button>
        </div>
      </dx-dialog>
    </div>

    <div [ngClass]="{ 'v-line': true, 'v-line-manage-mode': true }"></div>

    <div>
      <div class="container-label">
        <label class="label">Destination System</label>
      </div>
      <sysque-manage-mode-dest-panel-frame
        [key]="'dest'"
        [isSystemSource]="false"
        [showRootPath]="false"
        (pasteError)="onPasteError($event)"
        (showDuplicateError)="onShowDuplicateError($event)"
      ></sysque-manage-mode-dest-panel-frame>
    </div>
  </div>
  <div class="done-scope">
    <div class="ds-1">
      <div style="width: 75%; max-width: -webkit-fill-available">
        <sysque-alert
          class="alertWindow"
          [alertType]="alertType"
          [message]="warningMessage"
          [isDissmisible]="true"
          [alertName]="'Alert'"
          [showAlertName]="true"
          (closeButtonClicked)="onDismissClick()"
          *ngIf="showAlert"
        ></sysque-alert>
      </div>
      <span class="bottom-container">
        <dx-button class="done-button" text="Done" (onClick)="onDoneClick()"></dx-button>
      </span>
    </div>
  </div>
</div>
