export class CurateSearchActionsNames {
    static searchForCurateSearchAction = (): string => '[Curate Search] Curate search action';

    static searchForCurateSearchActionSuccess = (): string => '[Curate Search] Curate search action Success';
  
    static showAddFittingResultPanelAction = (): string => '[Toolbelt] Set Add Fitting Panel';

    static getCurateSearchOptionsAction = (): string => '[Curate Search] get curate Search options action ';

    static getCurateSearchOptionsActionSuccess = (): string => '[Curate Search] get curate Search options action success';
  

}