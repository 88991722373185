import { createReducer, on } from "@ngrx/store";
import { BrowseClickSuccess } from "./import-schedule-actions";

export const ImportScheduleKey = "ImportSchedule";

export interface ImportScheduleState {
    fileName?: string;
}

export const initialImportScheduleState: ImportScheduleState = {
    fileName: 'initial file name',
};

export const ImportScheduleReducer = createReducer(
    initialImportScheduleState,
  on(BrowseClickSuccess, (state, action) => ({
    ...state,
    fileName: action.response,
  })),
);