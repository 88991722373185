import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckBoxConfig } from '../../models/check-box-config';

@Component({
  selector: 'sysque-check-box-container',
  templateUrl: './check-box-container.component.html',
  styleUrls: ['./check-box-container.component.scss'],
})
export class SysqueCheckBoxContainerComponent {
  @Input() data: CheckBoxConfig[];

  @Output() circuitListUpdated: EventEmitter<any> = new EventEmitter<string[]>();
  onCheckBoxClicked(config: CheckBoxConfig) {
    config.checked = !config.checked;
    this.circuitListUpdated.emit(config);
  }
}
