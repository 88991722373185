<div class="text-area-description-container">
    <div class="text-area-label">{{label}}</div>
    <div class="text-area-container">
        <dx-text-area class="text-area" 
            width="{{width}}" 
            height="{{height}}" 
            value={{description}}
            (onValueChanged)="onValueChanged($event.value)">
        </dx-text-area>
    </div>
</div>