export class SysquePopupConfig{
    component: any;
    data!:any;
    visible!: boolean;
    componentTitle!: string;
    applicationTitle!: string;
    width!: number;
    height!: number;
    dragEnabled!: boolean;
    hideOnOutsideClick!: boolean;
    showCloseButton!: boolean;
}