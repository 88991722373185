import { ChannelCableTrayElbow } from "./channel-cable-tray-elbow"
import { ChannelCableTrayMultiPort } from "./channel-cable-tray-multi-port"
import { ChannelCableTrayVerticalElbow } from "./channel-cable-tray-vertical-elbow"
import { JunctionBoxCross } from "./junction-box-cross"
import { JunctionBoxElbow } from "./junction-box-elbow"
import { LadderCableTrayCross } from "./ladder-cable-tray-cross"
import { LadderCableTrayElbow } from "./ladder-cable-tray-elbow"
import { LadderCableTrayTee } from "./ladder-cable-tray-tee"
import { LadderCableTrayTransition } from "./ladder-cable-tray-transition"
import { LadderCableTrayVerticalElbow } from "./ladder-cable-tray-vertical-elbow"
import { MultiPort } from "./multi-port"

export interface Fitting {
    name: string
    channelCableTrayElbow?: ChannelCableTrayElbow[]
    channelCableTrayVerticalElbow?: ChannelCableTrayVerticalElbow[]
    channelCableTrayCross: any
    channelCableTrayTee: any
    channelCableTrayTransition: any
    channelCableTrayUnion: any
    channelCableTrayOffset: any
    channelCableTrayMultiPort?: ChannelCableTrayMultiPort[]
    electricalTypeItems: any
    ladderCableTrayElbow?: LadderCableTrayElbow[]
    ladderCableTrayVerticalElbow?: LadderCableTrayVerticalElbow[]
    ladderCableTrayCross?: LadderCableTrayCross[]
    ladderCableTrayTee?: LadderCableTrayTee[]
    ladderCableTrayUnion: any
    ladderCableTrayTransition?: LadderCableTrayTransition[]
    ladderCableTrayMultiPort: any
    isCustomContent?: string
    normal: any
    panelBoard: any
    Transformer: any
    switchBoard?: any[]
    OtherPanel?: any[]
    equipmentSwitch: any
    elbow: any
    transition: any
    cap: any
    union: any
    multiPort?: MultiPort[]
    junctionBoxTee: any
    junctionBoxCross?: JunctionBoxCross
    junctionBoxElbow?: JunctionBoxElbow[]
    junctionBox: any
  }