<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container" [style.height]="contentHeight">
    <div class="message">
      <div>
        Do you wish to delete <span class="message-sys"> [{{ data.msgSystemCategorymName }}] </span> and associated data
        ?
      </div>
    </div>
    <div class="alert-div">
      <sysque-alert
        [alertType]="alertType"
        [message]="alertMessage"
        [isDissmisible]="showClose"
        [alertName]="alertName"
        [showAlertName]="true"
        *ngIf="showOrNotShow"
        (closeButtonClicked)="onDismissClick()"
      ></sysque-alert>
    </div>
  </div>

  <div class="bottom-container">
    <dx-button class="delete-button-cancel" text="Cancel" (click)="closeWindow()"> </dx-button>
    <dx-button class="delete-button-proceed" text="Proceed" (click)="deleteSystemCategory()"> </dx-button>
  </div>
</sysque-frame>
