import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ResultInformation } from '../../models/result-information';
import { Inject, Injectable } from '@angular/core';
import { CurateSearchVm } from '../../models/curate-search-vm';
import {
  getCurateSearchOptionsAction,
  searchForCurateSearchAction,
  showAddFittingResultPanelAction,
} from './curate-search-actions';
import { selectCreateSearchOptions } from './curate-search-selector';
import { ICurateSearchFacade } from '../../../../../data-access/src/lib/domain/facades-models/curate-search-facade-interface';

import {
  ADD_FITTING_TAB_FACADE_TOKEN,
  IAddFittingTabFacade,
} from '../../../../../data-access/src/lib/domain/facades-models/add-fitting-tab-facade-interface';

@Injectable({ providedIn: 'root' })
export class CurateSearchFacade implements ICurateSearchFacade {
  constructor(
    private store: Store,
    @Inject(ADD_FITTING_TAB_FACADE_TOKEN) private addFittingTabFacade: IAddFittingTabFacade
  ) {}

  notifySearchFor(curateSearchVm: CurateSearchVm, count: number) {
    this.store.dispatch(searchForCurateSearchAction({ query: curateSearchVm, resetIndex: true, count: count }));
    this.store.dispatch(showAddFittingResultPanelAction({ mode: true }));
  }

  getCreateSearchOptions(): Observable<any> {
    this.store.dispatch(getCurateSearchOptionsAction());
    return this.store.select(selectCreateSearchOptions);
  }

  updateSelectorForResult() {
    this.addFittingTabFacade.notifySelectorChange.next('treeView');
  }
}
