export class CheckBoxConfig {
  label: string;
  checked: boolean;
  order?: number;

  public static create(label:string, checked:boolean, order?: number): CheckBoxConfig {
    const config = new CheckBoxConfig();
    config.label = label;
    config.checked = checked;
    if (order !== undefined) {
      config.order = order;
    }
    return config;
  }
}