<sysque-frame [componentTitle]="componentTitle">
  <div class="content-container">
    <div class="tabs-container">
      <modus-tabs [tabs]="tabs" size="small" (tabChange)="onTabChange($event)"></modus-tabs>
    </div>
    <div *ngIf="activeTab === 'Keyboard Shortcuts'">
      <div class="keyboard-shortcuts">
        <div class="keyboard-shortcuts-content" *ngFor="let item of items">
          <label class="feature-shortcut">{{ item.optionMenu }}</label>
          <div [ngClass]="isApplyButtonClicked && isElementInvalid(item) ? 'error-visible' : 'error-hidden'">
            <span id="iconAlert" class="error-icon" (mouseover)="showErrorMessage(item)"> &#9888; </span>
            <dx-tooltip
              target="#iconAlert"
              position="top"
              showEvent="mouseenter"
              position="top"
              hideEvent="mouseleave"
              [hideOnOutsideClick]="false"
            >
              <div>{{ errorMessage }}</div>
            </dx-tooltip>
          </div>

          <dx-select-box
            [items]="optionsKeyOne"
            [placeholder]="'Modifier'"
            (onValueChanged)="onValueChanged('modifiers', item, $event)"
            [value]="isRevertButtonClicked ? 'Modifier' : setDefaultModifierShortcuts(item)"
          ></dx-select-box>
          <dx-select-box
            [items]="optionsKeyTwo"
            [placeholder]="'Key 1'"
            (onValueChanged)="onValueChanged('keyOne', item, $event)"
            [value]="isRevertButtonClicked ? 'Key 1' : setDefaultKeyOneShortcuts(item)"
          ></dx-select-box>
          <dx-select-box
            [items]="optionsKeyThree"
            [placeholder]="'Key 2'"
            (onValueChanged)="onValueChanged('keyTwo', item, $event)"
            [value]="isRevertButtonClicked ? 'Key 2' : setDefaultKeyTwoShortcuts(item)"
          ></dx-select-box>
        </div>
      </div>
      <div class="restore-button">
        <dx-button
          id="restore-button-content"
          class="revert-container"
          text="{{ data.restoreName }}"
          (onClick)="onRevertClick()"
        ></dx-button>
      </div>
    </div>

    <div *ngIf="activeTab === 'Preferences'" class="preferences-container">
      <div class="preferences">
        <div class="switch-elements">
          <label>Enable Tool Tips</label>
          <dx-switch [value]="isEnableTooltips" (onValueChanged)="onEnableClick($event)"></dx-switch>
        </div>
        <div class="switch-elements">
          <label>Enable Worksharing Alerts</label>
          <dx-switch [value]="isWorksharingAlerts" (onValueChanged)="onWorksharingClick($event)"></dx-switch>
        </div>
        <div class="switch-elements">
          <label>Edit and Delete in Add Fittings view only</label>
          <dx-switch
            [value]="isEditDeleteAddFitting"
            (onValueChanged)="onEditDeleteAddFittingClick($event)"
          ></dx-switch>
        </div>
        <div class="switch-elements">
          <label>Auto Process Nominal Size Values</label>
          <dx-switch [value]="isNominalSize" (onValueChanged)="onNominalSizeClick($event)"></dx-switch>
        </div>

        <div class="description-container">
          <div class="description-element">
            <label>Image Description</label>
            <dx-select-box
              [items]="imageDescription"
              (onValueChanged)="onImageDescritpionChanged($event)"
              [value]="imageDescriptionChanged"
            ></dx-select-box>
          </div>
          <div class="description-element">
            <label>Alternate Description</label>
            <dx-select-box
              [items]="alternateDescription"
              (onValueChanged)="onAlternateDescritpionChanged($event)"
              [value]="alternateDescriptionChanged"
            ></dx-select-box>
          </div>
        </div>

        <div class="selection-element-container">
          <label>Fitting Rotation Angle Increment(degrees)</label>
          <input
            id="rotation-angle"
            type="number"
            [value]="fittingRotation !== null ? fittingRotation : ''"
            min="1"
            max="200"
            step="0.0001"
            (input)="onFittingRotationChange($event)"
          />
          <label>SysQue Connect Size(inches)</label>
          <input
            id="connect-size"
            type="number"
            [value]="connectSize !== null ? connectSize : ''"
            min="1"
            max="200"
            step="0.0001"
            (input)="onConnectSizeChange($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-container">
    <dx-button
      id="apply-button"
      class="apply-container"
      text="{{ data.applyName }}"
      (onClick)="onApplyClick()"
      [disabled]="isApplyButtonDisabled"
    ></dx-button>
  </div>
</sysque-frame>
