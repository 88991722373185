import { createReducer, on } from "@ngrx/store";
import { ValidateSelectionSuccess } from "./apply-id-actions";
import { ApplyConduitIdReturnCodes, ApplyEquipmentDeviceIdReturnCodes } from "../../models/apply-id-return-codes";

export const ApplyIdKey = "ApplyId";

export interface ApplyIdState {
    validSelection?: any; // can be ApplyConduitIdReturnCodes or ApplyEquipmentDeviceReturnCodes type
    //applyClickResponse: ApplyConduitIdReturnCodes | ApplyEquipmentDeviceIdReturnCodes ; //can be ApplyConduitIdReturnCodes or ApplyEquipmentDeviceReturnCodes type
}

export const initialApplyIdState: ApplyIdState = {
    validSelection: ApplyConduitIdReturnCodes.ValidSelection,
    //applyClickResponse: ApplyConduitIdReturnCodes.NoSelection,
};

export const ApplyIdReducer = createReducer(
    initialApplyIdState,
  on(ValidateSelectionSuccess, (state, action) => ({
    ...state,
    validSelection: action.response,
  })),
);