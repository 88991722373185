<div style="margin-left:17px !important;margin-top: 5px !important;">
<sysque-select-box
    [data]="categories$ | async"
    [key]="key"
    [disabled]="disabled"
    [showClearButton]="showClearButton"
    [placeholder]="placeholder"
    [width]="width"
    [height]="height"
    [(value)]="selectedCategory"
    (valueChanged)="onValueChanged($event)"
></sysque-select-box>
</div>