<div>
  <div class="title-container">
    <div class="title">Rotational Insertion</div>
  </div>
  <div id="gauge-demo">
    <dx-circular-gauge
      class="gauge"
      [value]="rotationValue"
      centerTemplate="centerTemplate"
      (click)="onGaugeClick($event)"
    >
      <dxo-margin [top]="0" [bottom]="0" [left]="0" [right]="0"> </dxo-margin>

      <dxo-value-indicator
        type="triangleNeedle"
        [offset]="0"
        [spindleGapSize]="5"
        width="10"
        color="rgba(81, 81, 81, 1)"
        [spindleSize]="15"
      >
      </dxo-value-indicator>
      <dxo-scale [startValue]="0" [endValue]="360" [tickInterval]="45" [minorTickInterval]="5">
        <dxo-label id="dxlabel" [useRangeColors]="true"></dxo-label>
      </dxo-scale>

      <dxo-range-container>
        <dxi-range [startValue]="0" [endValue]="360" color="transparent"></dxi-range>
      </dxo-range-container>
      <dxo-geometry [startAngle]="180" [endAngle]="540"> </dxo-geometry>
    </dx-circular-gauge>

    <div class="gauge-label">Insertion Angle</div>
    <div class="gauge-label gauge-number">
      <dx-number-box
        #numberBox
        [max]="360"
        [min]="0"
        format="#0°"
        [value]="rotationValue"
        [step]="1"
        [showSpinButtons]="true"
        (onValueChanged)="updateRotationValue($event)"
        (onInput)="onInputChange($event)"
      ></dx-number-box>
    </div>
    <div class="bottom-container">
      <dx-button id="apply-button" class="apply-button" text="Apply" (onClick)="applyGaugeValue()"></dx-button>
    </div>
  </div>
</div>
