import { InjectionToken } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface IAddFittingTabFacade{
  getTreeViewRootDirectory():any;
  initializeRootPath():any;
  getLocalStorageUpdate():BehaviorSubject<string>;
  notifySelectorChange : BehaviorSubject<string>;
}

export const ADD_FITTING_TAB_FACADE_TOKEN = new InjectionToken<IAddFittingTabFacade>('ADD_FITTING_TAB_FACADE_TOKEN');