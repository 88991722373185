<div class="checkbox-container">
    <label class="checkbox-label">{{label}}</label>
    <div class="checkbox-buttons-container">
    <div *ngFor="let item of items">
        <div class="dx-field">
            <div class="dx-field-value">
              <dx-check-box
                [value]="item.isChecked"
                text="{{item.label}}"
                (onValueChanged)="onValueChanged($event.value, item.label)">
              </dx-check-box>
            </div>
          </div>
        </div>
    </div>
</div>  