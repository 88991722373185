<div class="toolbelt-container">
  <sysque-switch-with-label-v class="mt-2"
    [label]="'Processing Enabled'"
    [value]="this.processingEnabled"
    (onProcessToggleChange)="onProcessToggleChange($event)"
  ></sysque-switch-with-label-v>
  
    <sysque-switch-with-label-v [style.pointer-events]="processingEnabled ? 'auto' : 'none'"
      [label]="'Process Fittings Only'"
      [value]="this.processFittings"
      (onProcessToggleChange)="onProcessToggleChange($event)"
    ></sysque-switch-with-label-v>

    <sysque-switch-with-label-v [style.pointer-events]="processingEnabled ? 'auto' : 'none'"
      [label]="'Show Non Standard'"
      (onProcessToggleChange)="onProcessToggleChange($event)"
      [value]="this.showNonStandard"
    ></sysque-switch-with-label-v>

    <sysque-shift-segment (emitShiftValue)="emitShiftValue($event)"></sysque-shift-segment>

    <sysque-rotate-element [emitRotationValue]="emitRotationValue"></sysque-rotate-element>

  <div class="list-container" [style.pointer-events]="processingEnabled ? 'auto' : 'none'">
    <div class="flex flex-row accordion" id="toolBeltAccordion">
      <div class="card" *ngFor="let data of toolItems; let i = index">
        <div
          class="card-header cursor-pointer"
          role="button"
          id="accordionHeading{{ i }}"
          data-toggle="collapse"
          [attr.data-target]="'#codeCollapse' + i"
          [attr.aria-controls]="'codeCollapse' + i"
          [attr.aria-expanded]="i === 0 ? true : false"
        >
          <div style="float: left">
            <img src="assets/img/{{ data.keyIcon }}" style="width: 15px; margin-right: 12px" />
          </div>
          <h6 class="mb-0" id="collapsible-group-item-{{ i }}">
            {{ data.key }}
          </h6>
        </div>
        <div
          [id]="'codeCollapse' + i"
          class="collapse"
          [attr.data-parent]="'#toolBeltAccordion'"
          [ngClass]="{ show: i === 0 }"
        >
          <ul class="list-group">
            <li
              class="list-group-item list-item-left-control pl-0"
              *ngFor="let item of data.items"
              [ngClass]="{
                disable: isItemDisabled(item),
                'toolbelt-menu-item': !isItemDisabled(item),
                selected: item.isSelected
              }"
              (click)="showSysquePopup(item)"
            >
              <div style="margin: 0 5px 0 5px">
                <img src="assets/img/{{ item.itemIcon }}" style="object-fit: fill; width: 25px" />
              </div>
              <div class="item-label">{{ item.itemName }}</div>
              <!-- Nested item list -->
              <div class="position-fixed pop-up-menu" [ngClass]="{'reporting-popup-menu' : isReportingDialog}" *ngIf="selectedItem && selectedItem === item">
                <ul class="list-group" tabindex="0">
                  <li (mouseout)="onFocusOut($event)"
                    class="list-group-item list-item-left-control pl-0"
                    *ngFor="let nestedItem of selectedItem.items"
                    [ngClass]="{ disable: nestedItem.disabled, 'toolbelt-menu-item': !nestedItem.disabled }"
                    (click)="showSysquePopup(nestedItem)"
                    tabindex="0"
                  >
                    <div style="margin: 0 5px 0 5px" tabindex="0">
                      <img src="assets/img/{{ nestedItem.itemIcon }}" style="object-fit: fill; width: 25px" tabindex="0" alt="toolbelt menu icon"/>
                    </div>
                    <div class="item-label" tabindex="0">{{ nestedItem.itemName }}</div>
                  </li>
                </ul>
              </div>
              <!-- End of nested item list -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
