<div class="properties-list-container">
    <div *ngFor="let item of items">
        <div class="dx-field">
            <div class="dx-field-label-properties">{{item.label}}</div>
            <div *ngIf="item.isEditable; else elseBlock" class="dx-field-value">
                <dx-text-box class="dx-text-box"
                    [value]="item.value" 
                    (onValueChanged)="onValueChanged($event.value, item.label)">
                </dx-text-box>
            </div>
            <ng-template #elseBlock>
                <div class="dx-field-value">{{item.value}}</div>
            </ng-template>
        </div>
    </div>
</div>