import { createAction, props } from "@ngrx/store";
import { CurateSearchActionsNames } from "./curate-search-action-names";
import { CurateSearchVm } from "../../models/curate-search-vm";
import { ResultInformation } from "../../models/result-information";

export const searchForCurateSearchAction = createAction(
    CurateSearchActionsNames.searchForCurateSearchAction(),
    props<{ query: CurateSearchVm, resetIndex: boolean, count:number }>()
  );

  export const searchForCurateSearchActionSuccess = createAction(
    CurateSearchActionsNames.searchForCurateSearchActionSuccess(),
    props<{ response: ResultInformation[], lastCurateSearchQuery: CurateSearchVm, resetIndex: boolean }>()
  );

  export const getCurateSearchOptionsAction = createAction(
    CurateSearchActionsNames.getCurateSearchOptionsAction()
  );

  export const getCurateSearchOptionsActionSuccess = createAction(
    CurateSearchActionsNames.getCurateSearchOptionsActionSuccess(),
    props<{ response: any }>()
  );

  export const showAddFittingResultPanelAction = createAction(
    CurateSearchActionsNames.showAddFittingResultPanelAction(),
    props<{ mode: boolean }>()
  );
  