<dx-select-box
  #dxSelectBox
  *ngIf="!key"
  width="{{ width }}"
  height="{{ height }}"
  [items]="data"
  [showClearButton]="showClearButton"
  (onValueChanged)="onValueChanged($event)"
  [ngClass]="cssClass"
  [value]="value"
  placeholder="{{ placeholder }}"
  [dropDownOptions]="dropDownOptions"
  (onFocusOut)="onFocusOut()"
>
  <div *dxTemplate="let data of 'dropDownButton'">
    <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
  </div>
</dx-select-box>

<dx-select-box
  #dxSelectBox
  width="{{ width }}"
  height="{{ height }}"
  *ngIf="key"
  displayExpr="{{ key }}"
  valueExpr="{{ key }}"
  [dataSource]="data"
  [disabled]="disabled"
  [showClearButton]="showClearButton"
  [value]="value"
  placeholder="{{ placeholder }}"
  (onValueChanged)="onValueChanged($event)"
  [dropDownOptions]="dropDownOptions"
  (onFocusOut)="onFocusOut()"
>
  <div *dxTemplate="let data of 'dropDownButton'">
    <img alt="Custom icon" src="assets/img/chevron-down-med.svg" class="custom-select-system-icon" />
  </div>
</dx-select-box>
