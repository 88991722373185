export const toolGroupsData = [{
    key: 'Detailing',
    keyIcon: 'Icon=Detailing@2x.png',
    apps: ['Electrical'],
    items: [
        {
            itemName: 'Align Couplings',
            itemIcon: 'AlignCouplings.png',
            items: []
        },
        {
            itemName: 'Apply Equipment Details',
            itemIcon: 'Trade=Electrical, Icon=DeviceDetails, Size=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'Add Data Parameters',
            itemIcon: 'Apply_data.png',
            items: [
                {
                    itemName:'Apply Conduit ID',
                    itemIcon: 'conduit-id_32x32.png',
                    disabled: false
                },
                {
                    itemName:'Apply Equipment/Device ID',    
                    itemIcon: 'apply-equipment-device-id_32x32.png',
                    disabled: false

                },
                {
                    itemName:'Apply Panel/Circuit Data',
                    itemIcon: 'apply-panel-circuit-data_32x32.png',
                    disabled: false
                }
            ]
        },
        {
            itemName: 'Code Checking',
            itemIcon: 'Trade=Electrical, Icon=Code Check, Size=Small, Enabled=True64w.png',
            items: [
                {
                    itemName:'Check for Exceeding Fill %',
                    itemIcon: 'Apply_data.png',
                    disabled: false
                },
                {
                    itemName:'Check for Exceeding Max Angle',    
                    itemIcon: 'Apply_data.png',
                    disabled: false
                }
            ]
        },
        {
            itemName: 'Conduit Run Edit',
            itemIcon: 'Conduit_Run_Edit.png',
            items: []
        },
        {
            itemName: 'Convert System',
            itemIcon: 'Trade=All, Icon=SystemConvert, SIze=Small, Enabled=True@2x.png',
            items: []
        },
        {
            itemName: 'Direct Take Off',
            itemIcon: 'Trade=All, Icon=Takeoff-Connect, SIze=Small, Enabled=True@2x.png',
            items: []
        },
        {
            itemName: 'Field fab',
            itemIcon: 'Field Fab.png',
            items: []
        },
        {
            itemName: 'Go To System',
            itemIcon: 'Trade=Electrical, Icon=Goto System, Size=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'Object Unite',
            itemIcon: 'Multi-TrimExtend.png',
            items: []
        },
        {
            itemName: 'Place Coupling',
            itemIcon: 'Trade=Electrical, Icon=Add Coupler, Size=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'Reporting',
            itemIcon: 'Trade=Shared Trade Button Icons, Icon=Reporting, Size=Small, Enabled=True64w.png',
            items: [
                {
                    itemName:'Panel/Circuit Information',
                    itemIcon: 'report-panel-circuit-info_32x32.png',
                    disabled: false
                },
                {
                    itemName:'Conduit Information',    
                    itemIcon: 'report-conduit-info_32x32.png',
                    disabled: false
                },
                {
                    itemName:'Cable Tray Information',
                    itemIcon: 'cable-tray-info_32x32.png',
                    disabled: false
                }
            ]
        },
    ]
},


{
    key: 'Tools',
    keyIcon: 'Icon=Tools@2x.png',
    apps: ['Electrical'],
    items: [
        {
            itemName: 'Apply System Data',
            itemIcon: 'Trade=All, Icon=ApplySystem, SIze=Small, Enabled=True@2x.png',
            items: [],
            disabled: false
        },
        {
            itemName: 'Apply Nominal Size',
            itemIcon: 'Icon=Electrical_Apply Nominal,State=Default.png',
            items: [],
            disabled: false
        },
        {
            itemName: 'Download Missing Families',
            itemIcon: 'Trade=Electrical, Icon=DownloadMissing, Size=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'Electrical Settings',
            itemIcon: 'Trade=All, Icon=GeneralSettings, SIze=Small, Enabled=True@2x.png',
            items: [],
        },
        {
            itemName: 'Import Schedule',
            itemIcon: 'Trade=All, Icon=ImportSchedule, SIze=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'Update Extended Data',
            itemIcon: 'UpdateExtendedData.png',
            items: []
        },
        {
            itemName: 'Update Families',
            itemIcon: 'Trade=Electrical, Icon=Refresh, Size=Small, Enabled=True64w.png',
            items: []
        },
    ]
},


{
    key: 'Workspace',
    keyIcon: 'Icon=Wotkspace@2x.png',
    apps: ['Electrical'],
    items: [
        {
            itemName: 'About Electrical',
            itemIcon: 'Trade=Electrical, Icon=AboutElectrical, Size=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'Annotations Settings',
            itemIcon: 'AnnotationSettings.png',
            items: []
        },
        {
            itemName: 'Change Priority',
            itemIcon: 'Trade=All, Icon=PriorityPosition, SIze=Small, Enabled=True@2x.png',
            items: []
        },
        {
            itemName: 'Configuration Location',
            itemIcon: 'Trade=All, Icon=ConfigurationFilePath, SIze=Small, Enabled=True@2x.png',
            items: []
        },
        {
            itemName: 'Configuration Export',
            itemIcon: 'Configuration_Export.png',
            items: []
        },
        {
            itemName: 'Specification Settings',
            itemIcon: 'Trade=Electrical, Icon=Specifications, Size=Small, Enabled=True64w.png',
            items: []
        },
        {
            itemName: 'System Manager',
            itemIcon: 'Trade=All, Icon=SystemManager, SIze=Small, Enabled=True@2x.png',
            items: []
        },
    ]
}

]