import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromFittingTreeViewReducer from './fitting-tree-view-reducer';
import { FittingTreeViewState } from "./fitting-tree-view-reducer";

export const selectFittingTreeViewState = createFeatureSelector<FittingTreeViewState>(fromFittingTreeViewReducer.FittingTreeViewKey);

export const selectUpdatedTreeViewItem = createSelector(selectFittingTreeViewState, (state) => state.updatedTreeViewItem);

export const selectTreeViewItems = createSelector(selectFittingTreeViewState, (state) => state.treeViewItems);

export const selectTreeViewResultInformation = createSelector(selectFittingTreeViewState, (state) => state.treeViewResultInformation);
