<div class="location-container">
    <label class="location-label">{{label}}</label>
    <div class="location-right-container">
        <div class="dx-field">
            <dx-text-box 
            placeholder="{{placeholder}}"
            value="{{locationPath}}"
            (onValueChanged)="onValueChanged($event.value)">
            </dx-text-box>
        </div>
        <div class="group-btn-container">
            <modus-button button-style="borderless" (click)="onClick()">
                <img src="assets/img/Group.png" style="transform: rotate(-90deg)" alt="logo" />
            </modus-button>
        </div>
    </div>
</div>