export interface HangerSpecification {
    specId:           string;
    id:               string;
    type:             string;
    unit:             string;
    path:             string;
    name:             string;
    systemName:       string;
    diameter:         string;
    depth:            string;
    width:            string;
    spacing:          string;
    distFromFittings: string;
    distFromCoupling: string;
    distFromBeam:     string;
    errHangRodLen:    string;
    errHangColor:     string;
    rodDia:           string;
    noOverride:       string;
}