<div style="width: '{{width}}'">
<sysque-select-box
    [data]="systemList"
    [key]="key"
    [disabled]="disabled"
    [showClearButton]="showClearButton"
    [placeholder]="placeholder"
    [width]="width"
    [height]="height"
    (valueChanged)="onValueChanged($event)"
    [(value)]="selectedSystem"
></sysque-select-box>
</div>