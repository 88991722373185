import { Component, EventEmitter, Output } from '@angular/core';
import { sendPopupRoutingAction, setAddFittingModeAction } from '../../+state/toolbelt.actions';
import { Store } from '@ngrx/store';
import { GlobalConstants } from '../../constants/global-constants';
import { SysquePopupConfig } from '../../models/sysque-popup-config';
import { createDialog } from '../../popup-dialog-factory/popup-dialog.facade';

@Component({
  selector: 'sysque-add-fitting-btn',
  templateUrl: './add-fitting-btn.component.html',
  styleUrls: ['./add-fitting-btn.component.scss'],
})
export class AddFittingBtnComponent {
  rootPathValue = "";
  constructor(private store: Store) { }
  @Output() notify = new EventEmitter();
  setAddFittingMode() {
    this.notify.emit();
    let sysquePopupConfig = new SysquePopupConfig();
    createDialog(sysquePopupConfig, "Add Fittings");
    this.store.dispatch(setAddFittingModeAction({ mode: true }))
    this.store.dispatch(sendPopupRoutingAction({ route: GlobalConstants.ADD_FITTING_DIALOG, width: sysquePopupConfig.width, height: sysquePopupConfig.height }));
  }
}